import React from 'react'
import styles from './FormField.module.css'
import c from 'classnames'
import Text from '../../atoms/text/Text'

const FormField = ({ field, error, size = 'medium', className }) => {
  return (
    <div className={c(styles.container, size && styles[size], className)}>
      {field}
      {error && (
        <Text variant="negative" element="span" className={styles.error}>
          {error}
        </Text>
      )}
    </div>
  )
}

export default React.memo(FormField)
