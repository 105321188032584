import React, { useContext, useState, useCallback } from 'react'
import Modal from '../../components/atoms/modal/Modal'

const ModalContext = React.createContext()
const { Consumer, Provider } = ModalContext

export const ModalProvider = ({ target = null, children, ...rest }) => {
  const [modalOpened, setModalOpened] = useState(false)
  const [modalParams, setModalParams] = useState({})

  const showModal = useCallback((params) => {
    if (!params.Component) {
      return
    }

    setModalParams(params)
    setModalOpened(true)
  }, [])

  const hideModal = useCallback(() => {
    setModalOpened(false)
  }, [])

  const handleModalOpened = useCallback(() => {
    // Perform any operation
  }, [])

  const hadleModalClosed = useCallback(() => {
    setModalParams({})
  }, [])

  const portalTarget = target ? target : document.body

  return (
    <Provider value={{ showModal, hideModal }}>
      {children}
      <Modal
        params={modalParams}
        opened={modalOpened}
        target={portalTarget}
        showModal={showModal}
        hideModal={hideModal}
        onModalOpened={handleModalOpened}
        onModalClosed={hadleModalClosed}
      />
    </Provider>
  )
}

export const ModalCosumer = ({ children }) => {
  return <Consumer>{(context) => children(context)}</Consumer>
}

export const withModalManager = (Component) => {
  return React.forwardRef((props, ref) => {
    return <ModalCosumer>{(context) => <Component modalManager={context} {...props} ref={ref} />}</ModalCosumer>
  })
}

export const useModal = () => {
  const ctx = useContext(ModalContext)

  if (!ctx) {
    throw Error('The `useModal` hook must be called from a descendent of the `ModalProvider`.')
  }

  return {
    showModal: ctx.showModal,
    hideModal: ctx.hideModal,
  }
}
