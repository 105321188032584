import React from 'react'
import styles from './Loader.module.css'
import c from 'classnames'

const Loader = ({ className, variant = 'white' }) => {
  return (
    <div data-testid="loader" className={c(styles.container, styles[variant], className)}>
      <span />
      <span />
      <span />
      <span />
    </div>
  )
}

export default React.memo(Loader)
