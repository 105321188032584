export const initialState = {
  isSearchActive: false,
  searchParams: {},
  maxRenderedPositions: 50,
  hiddenRoutes: new Set(),
}

export const SET_MAX_RENDERED_POSITIONS = 'SET_MAX_RENDERED_POSITIONS'
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS'
export const SHOW_ROUTE = 'SHOW_ROUTE'
export const HIDE_ROUTE = 'HIDE_ROUTE'
export const RESET_ROUTES = 'RESET_ROUTES'

export const reducer = (prevState, { type, payload }) => {
  switch (type) {
    case SET_MAX_RENDERED_POSITIONS:
      return {
        ...prevState,
        maxRenderedPositions: payload.maxRenderedPositions,
      }

    case SET_SEARCH_PARAMS:
      return {
        ...prevState,
        isSearchActive: true,
        searchParams: payload.searchParams || {
          limit: 50,
        },
        maxRenderedPositions: payload.maxRenderedPositions
          ? payload.maxRenderedPositions
          : prevState.maxRenderedPositions,
        hiddenRoutes: new Set(),
      }

    case CLEAR_SEARCH_PARAMS:
      return {
        ...prevState,
        isSearchActive: false,
        searchParams: {},
        hiddenRoutes: new Set(),
      }

    case HIDE_ROUTE: {
      const hiddenRoutes = prevState.hiddenRoutes
      hiddenRoutes.add(payload.index)
      return {
        ...prevState,
        hiddenRoutes: new Set(hiddenRoutes),
      }
    }

    case SHOW_ROUTE: {
      const hiddenRoutes = prevState.hiddenRoutes
      hiddenRoutes.delete(payload.index)
      return {
        ...prevState,
        hiddenRoutes: new Set(hiddenRoutes),
      }
    }

    case RESET_ROUTES:
      return {
        ...prevState,
        hiddenRoutes: new Set(),
      }

    default:
      return prevState
  }
}
