import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import styles from './MenuLink.module.css'
import c from 'classnames'

const MenuLink = ({ className, Icon, to, ...rest }) => {
  const match = useRouteMatch({
    path: to,
    strict: true,
    sensitive: true,
    exact: true,
  })

  return (
    <div className={c(styles.container, className)}>
      <Link to={to} {...rest}>
        <Icon size="huge" variant={match ? 'primary' : 'white'} {...rest} />
      </Link>
    </div>
  )
}

export default React.memo(MenuLink)
