import React from 'react'
import styles from './Menu.module.css'
import c from 'classnames'
import MenuLink from './components/menuLink/MenuLink'
import routes from '../../../config/routes'
import IconCars from '../../icons/IconCars'
import IconLogout from '../../icons/IconLogout'
import useLogout from '../../../hooks/useLogout'

const Menu = ({ className }) => {
  const handleLogoutClick = useLogout()

  return (
    <nav className={c(styles.container, className)}>
      <ul className={styles.items}>
        <li className={styles.item}>
          <MenuLink to={routes.home} Icon={IconCars} />
        </li>

        <li className={styles.item}>
          <MenuLink onClick={handleLogoutClick} Icon={IconLogout} size="big" />
        </li>
      </ul>
    </nav>
  )
}

export default React.memo(Menu)
