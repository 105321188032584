import L from 'leaflet'
import React from 'react'
import { Marker } from 'react-leaflet'
import { getVehicleState, getVehicleType } from '../../../modules/vehicles/utils'
import markers from '../../../utils/markers'
import VehicleTooltip from './components/vehicleTooltip/VehicleTooltip'

const VehicleMarker = ({ vehicle, position, tooltipEnabled = false, ...rest }) => {
  const vehicleId = vehicle?.idvehiculo
  const vehicleState = getVehicleState(position)
  const vehicleType = getVehicleType(vehicleId)
  const marker = markers[vehicleType]
  let icon = L.icon({
    iconUrl: marker?.state[vehicleState],
    iconRetinaUrl: marker?.state[vehicleState],
    iconSize: [marker?.width, marker?.height],
    popupAnchor: [0, 0 - Math.ceil(marker?.height / 2)],
  })

  if (!vehicle) {
    return null
  }

  return (
    <Marker position={[position.latitud / 100000, position.longitud / 100000]} icon={icon} {...rest}>
      {tooltipEnabled && (
        <VehicleTooltip
          isLastValidPosition={!!position?.isLastValidPosition}
          vehicle={vehicle}
          offset={L.point(0, 0 - Math.ceil(marker?.height ?? 1 / 2))}
        />
      )}
    </Marker>
  )
}

export default React.memo(VehicleMarker)
