import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconChevronCircleDown = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 48 48">
      <path d="M24.012 48C37.132 48 48 37.133 48 23.988 48 10.868 37.108 0 23.988 0 10.868 0 0 10.867 0 23.988 0 37.133 10.892 48 24.012 48zm0-4.283c-10.941 0-19.705-8.763-19.705-19.73 0-10.941 8.739-19.73 19.68-19.73a19.709 19.709 0 0119.755 19.73c.025 10.967-8.788 19.73-19.73 19.73zm2.377-12.872l9.11-9.68c.718-.767.742-1.906.074-2.574-.743-.792-1.93-.792-2.624-.05l-8.937 9.482-8.936-9.481c-.693-.743-1.906-.743-2.624.049-.693.668-.669 1.807.05 2.575l9.134 9.679c1.46 1.56 3.268 1.56 4.753 0z" />
    </svg>
  </i>
)

IconChevronCircleDown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconChevronCircleDown.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconChevronCircleDown
