const MAX_AGE = 60 * 60 * 24 * 15 // Let's expire the cookie in 15 days
const DOMAINS = process.env.REACT_APP_DOMAIN || ''
const COOKIE_NAME = 'montesperitos'
const disabledCookieService = process.env.REACT_APP_DISABLE_COOKIE_SERVICE === 'true'

const CookieService = {
  currentCookie: '',

  setCookie: (token) => {
    if (disabledCookieService) {
      return
    }

    const cookie = `${COOKIE_NAME}=${token};max-age=${MAX_AGE};${DOMAINS ? `secure;domain=${DOMAINS};` : ''}path=/`
    document.cookie = cookie
    CookieService.currentCookie = token
  },

  readCookie: () => {
    if (disabledCookieService) {
      return ''
    }
    const name = `${COOKIE_NAME}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        const readCookie = c.substring(name.length, c.length)
        CookieService.currentCookie = readCookie
        return readCookie
      }
    }
    CookieService.currentCookie = ''
    return ''
  },

  deleteCookie: () => {
    if (disabledCookieService) {
      return ''
    }
    const now = new Date()
    const beforeNow = new Date(now.getDate() - 1)
    const expires = `expires=${beforeNow.toGMTString()}`
    const cookie = `${COOKIE_NAME}=;${expires};max-age=0;${DOMAINS ? `secure;domain=${DOMAINS};` : ''}path=/`
    document.cookie = cookie
    CookieService.currentCookie = ''
  },
}

export default CookieService
