export const SET_AUTH_STATUS = 'AUTH/SET_AUTH_STATUS'
export const SET_AUTH_DATA = 'AUTH/SET_AUTH_DATA'

export const setStatus = ({ status }) => {
  return {
    type: SET_AUTH_STATUS,
    payload: { status },
  }
}

export const setAuthData = ({ data }) => {
  return {
    type: SET_AUTH_DATA,
    payload: { data },
  }
}
