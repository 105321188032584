import React from 'react'
import PropTypes from 'prop-types'
import styles from './ButtonIcon.module.css'
import c from 'classnames'

const ButtonIcon = ({ icon, variant, className, disabled, onClick, ...rest }) => {
  return (
    <button
      className={c(styles.container, styles[variant], disabled && styles.disabled, className)}
      disabled={disabled}
      onClick={onClick}
      type="button"
      {...rest}
    >
      {icon}
    </button>
  )
}

ButtonIcon.propTypes = {
  extraClass: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  onClick: PropTypes.func,
}

export default React.memo(ButtonIcon)
