import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconTag = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 30 30">
      <path d="M12.931 30c1.003-.005 1.995-.494 2.979-1.468l12.98-13.023c.347-.337.597-.646.752-.927.154-.28.253-.588.295-.92.042-.333.063-.738.063-1.215V7.403c0-.487-.042-.885-.126-1.194a2.398 2.398 0 00-.436-.857 10.613 10.613 0 00-.829-.913l-3.034-3.062a12.598 12.598 0 00-.934-.815 2.477 2.477 0 00-.857-.436C23.479.042 23.088 0 22.61 0h-5.058c-.487 0-.894.021-1.222.063a2.41 2.41 0 00-.906.302c-.276.16-.588.408-.934.745L1.468 14.104C.494 15.088.005 16.083 0 17.09c-.005 1.006.49 2.006 1.482 2.999l8.457 8.443c.993.983 1.99 1.473 2.992 1.468zm7.39-19.127c-.534 0-.979-.18-1.335-.54-.356-.361-.534-.808-.534-1.342a1.8 1.8 0 01.534-1.314c.356-.36.8-.54 1.335-.54.534 0 .978.18 1.334.54a1.8 1.8 0 01.534 1.314c0 .534-.178.98-.534 1.341-.356.361-.8.541-1.334.541z" />
    </svg>
  </i>
)

IconTag.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconTag.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconTag
