import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const useSearchHistory = (maxHistory = 5) => {
  const [searchHistory, setSearchHistory] = useState(() => {
    const savedHistory = sessionStorage.getItem('searchHistory')
    return savedHistory ? JSON.parse(savedHistory) : []
  })

  useEffect(() => {
    sessionStorage.setItem('searchHistory', JSON.stringify(searchHistory))
  }, [searchHistory])

  const addSearch = useDebouncedCallback((search) => {
    setSearchHistory((prevHistory) => {
      const newHistory = [search, ...prevHistory.filter((item) => item !== search)].slice(0, maxHistory)
      return newHistory
    })
  }, 600)

  const clearHistory = () => {
    setSearchHistory([])
  }

  return { searchHistory, addSearch, clearHistory }
}

export default useSearchHistory
