import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { useEffect } from 'react'
import 'react-day-picker/lib/style.css'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.module.css'
import PrivateRoute from './components/molecules/privateRoute/PrivateRoute'
import routes from './config/routes'
import { ModalProvider } from './hooks/modal/ModalProvider'
import { TrackAssistantProvider } from './hooks/trackAssistant/useTrackAssistant'
import AuthService from './modules/auth/service'
import { ItmosQueryClient } from './modules/ItmosQueryClient'
import DashboardScreen from './screens/dashboard/DashboardScreen.js'
import LoginScreen from './screens/login/LoginScreen'
import store from './store/index'

const PiwikReactRouter = require('piwik-react-router')

PiwikReactRouter({
  url: 'https://piwik.itmos.com',
  siteId: 1,
})

const App = () => {
  useEffect(() => {
    AuthService.loginSilently()
  }, [])

  return (
    <QueryClientProvider client={ItmosQueryClient}>
      <Provider store={store}>
        <TrackAssistantProvider>
          <ModalProvider>
            <Router>
              <Switch>
                <Route path={routes.login} component={LoginScreen} />
                <PrivateRoute path={routes.home} component={DashboardScreen} />
              </Switch>
            </Router>
          </ModalProvider>
        </TrackAssistantProvider>
        <ToastContainer transition={Slide} />
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
