import { createSelector } from 'reselect'

const DOMAIN = 'auth'

const _getAuth = (state) => state[DOMAIN]

const _getAuthStatus = (state) => {
  return _getAuth(state).status
}

const _getAuthenticatedUserId = (state) => {
  const auth = _getAuth(state)
  return auth.id
}

const _getAuthenticatedUser = (state) => {
  const auth = _getAuth(state)
  const user = { ...auth }
  delete user.status
  return user
}

export const getAuthStatus = createSelector(_getAuthStatus, (status) => status)

export const getAuthUserName = createSelector(_getAuth, (data) => data && data.firstname)

export const getAuthenticatedUserId = createSelector(_getAuthenticatedUserId, (userId) => userId)

export const getAuthenticatedUser = createSelector(_getAuthenticatedUser, (user) => user)

export const getCompanyId = createSelector(_getAuthenticatedUser, (user) => {
  return user?.idempresa
})

export const getCompany = createSelector(_getAuthenticatedUser, (user) => {
  return user?.empresa[0]
})
