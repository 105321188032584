import React, { useCallback } from 'react'
import styles from './ExportButton.module.css'
import c from 'classnames'
import ButtonIcon from 'components/atoms/buttonIcon/ButtonIcon'
import IconList from 'components/icons/IconList'
import { useModal } from '../../../../../hooks/modal/ModalProvider'
import { useParams } from 'react-router-dom'
import ExportLocationsModal from '../../../../../components/molecules/modals/exportLocations/ExportLocationsModal'

const ExportButton = ({ className, searchParams, maxPostions, ...rest }) => {
  const { showModal } = useModal()
  const { vehicleId } = useParams()

  const openSearchLocationsModal = useCallback(() => {
    showModal({
      Component: <ExportLocationsModal vehicleId={vehicleId} searchParams={searchParams} maxPostions={maxPostions} />,
      className: styles.modal,
      dismissible: false,
    })
  }, [maxPostions, searchParams, showModal, vehicleId])

  return (
    <ButtonIcon
      className={c(styles.button, className)}
      variant="white"
      icon={<IconList variant="dark" />}
      onClick={openSearchLocationsModal}
      {...rest}
    />
  )
}

export default React.memo(ExportButton)
