import React, { useCallback } from 'react'
import { useModal } from './ModalProvider'
import ConfirmModal from '../../components/molecules/modals/confirm/ConfirmModal'

const useUnsavedContentModal = () => {
  const { showModal, hideModal } = useModal()

  const showConfirmModal = useCallback(
    ({ dismissible = false, extraClass, ...options }) => {
      return showModal({
        Component: (
          <ConfirmModal
            title="Ooops!"
            description="Parece que no has guardado tus cambios. Si continuas los perderás, ¿quieres continuar?"
            confirmActionLabel="Continuar"
            confirmActionVariant="negative"
            {...options}
          />
        ),
        dismissible,
        extraClass,
      })
    },
    [showModal],
  )

  const hideConfirmModal = useCallback(() => {
    hideModal()
  }, [hideModal])

  return { showConfirmModal, hideConfirmModal }
}

export default useUnsavedContentModal
