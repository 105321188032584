import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconChevronDown = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 30 30">
      <path d="M14.996 23.35c.265 0 .514-.049.749-.145.234-.096.442-.253.622-.47L29.464 9.35c.36-.348.541-.77.541-1.262 0-.337-.081-.644-.244-.92a1.842 1.842 0 00-.649-.659 1.77 1.77 0 00-.929-.243c-.493 0-.926.18-1.299.54l-11.881 12.17L3.108 6.806a1.75 1.75 0 00-1.281-.54 1.77 1.77 0 00-.93.243c-.27.163-.486.382-.649.659a1.784 1.784 0 00-.243.92c0 .252.048.484.144.694.096.21.229.4.397.568l13.079 13.404c.409.397.866.595 1.37.595z" />
    </svg>
  </i>
)

IconChevronDown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconChevronDown.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconChevronDown
