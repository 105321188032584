import moment from 'moment-timezone'

export const filterStops = ({ positions }) => {
  if (!positions || positions?.length === 0) {
    return []
  }

  const stops = []
  let lastState = null

  positions.forEach((position) => {
    const currentState = getVehicleState(position)
    if (currentState === 'off' && lastState === 'on') {
      stops.push(position)
    }

    lastState = currentState
  })
  return stops
}

export const getVehicleState = (position) => {
  return (position.t0 & 4) !== 4 ? 'off' : 'on'
}

export const getVehicleType = (vehicleId) => {
  return 'car'
}

export const isValidPosition = (position = {}) => {
  const { latitud, longitud } = position
  return latitud && latitud !== 0 && longitud && longitud !== 0
}

export const getVehicleDriver = (vehicle) => {
  if (!vehicle) {
    return 'N/A'
  }
  const { kit } = vehicle
  const { driverId = '' } = kit
  return driverId ? atob(driverId) : 'N/A'
}

export const getVehicleSpeed = (vehicle) => {
  if (!vehicle) {
    return 'N/A'
  }
  const { kit } = vehicle
  if (!kit || (typeof kit.datosserie.speed === 'undefined' && typeof kit.velocidad === 'undefined')) {
    return 'N/A'
  }
  return kit.datosserie.speed || kit.velocidad
}

export const getVehicleFuel = (vehicle) => {
  if (!vehicle) {
    return null
  }
  const { kit } = vehicle
  if (!kit || typeof kit?.datosserie?.fuel === 'undefined') {
    return null
  }
  return kit.datosserie.fuel
}

export const getRouteHours = (route) => {
  const firstPostion = route?.[route.length - 1]
  const lastPostion = route?.[0]

  return {
    day: moment.utc(firstPostion.horagps).tz('Europe/Madrid').format('DD/MM/YYYY'),
    start: moment.utc(firstPostion.horagps).tz('Europe/Madrid').format('HH:mm'),
    end: moment.utc(lastPostion.horagps).tz('Europe/Madrid').format('HH:mm'),
  }
}

export const getRouteDistance = (route) => {
  const firstPostion = route?.[route.length - 1]
  const lastPostion = route?.[0]
  const kms = lastPostion?.distanciatotal - firstPostion?.distanciatotal
  return parseFloat(kms ?? 0)?.toFixed?.(2) ?? '0'
}

export const getRouteDestination = (route) => {
  const lastPostion = route?.[0]
  return lastPostion?.place
}
