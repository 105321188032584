import React from 'react'
import { createPortal } from 'react-dom'
import styles from './Modal.module.css'
import c from 'classnames'
import { CSSTransition } from 'react-transition-group'

const Modal = ({
  hideModal,
  showModal,
  opened,
  target,
  params: { className, innerExtraClass, Component, dismissible },
  onModalOpened,
  onModalClosed,
  ...rest
}) => {
  return createPortal(
    <CSSTransition
      in={opened}
      timeout={300}
      unmountOnExit
      classNames="modal"
      onEnter={onModalOpened}
      onExited={onModalClosed}
    >
      <div className={styles.container}>
        <div className={styles.overlay} onClick={dismissible ? hideModal : null}></div>

        <div className={c(styles.modal, className)}>
          <div className={c(styles.content, innerExtraClass)}>
            {Component && React.cloneElement(Component, { showModal, hideModal })}
          </div>
        </div>
      </div>
    </CSSTransition>,
    target,
  )
}

export default React.memo(Modal)
