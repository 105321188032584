import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import styles from './LoginScreen.module.css'
import loginFormSchema from './schemas/LoginFormSchema'
import { useLocation, useHistory } from 'react-router-dom'
import AuthService from '../../modules/auth/service'
import Card from '../../components/atoms/card/Card'
import Text from '../../components/atoms/text/Text'
import Input from '../../components/atoms/input/Input'
import Button from '../../components/atoms/button/Button'
import Logo from '../../components/atoms/logo/Logo'
import FormField from '../../components/molecules/formField/FormField'
import { toast } from 'react-toastify'

const LoginScreen = ({ ...rest }) => {
  const location = useLocation()
  const history = useHistory()
  const { state } = location

  useEffect(() => {
    if (state && state.clean) {
      AuthService.logout()
    }
  }, [state])

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: loginFormSchema,
    onSubmit: async (values) => {
      try {
        await AuthService.login({
          user: values.username,
          pass: values.password,
        })
        history.replace(state && state.from ? state.from : { pathname: '/' })
      } catch (error) {
        console.info(error)
        toast('Opps! Parece que el usuario o la contraseña no son correctos', {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        })
      }
    },
  })

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Card className={styles.welcomeCard}>
          <Text weight="600" size="l" variant="white">
            ¡Bienvenido de nuevo!
          </Text>
        </Card>
        <Card className={styles.formCard}>
          <Logo className={styles.logo} />

          <form onSubmit={formik.handleSubmit}>
            <FormField
              field={
                <Input
                  name="username"
                  placeholder="Usuario"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  toUpperCase={false}
                />
              }
              error={formik.touched.username && formik.errors.username && formik.errors.username}
            />

            <FormField
              field={
                <Input
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  toUpperCase={false}
                />
              }
              error={formik.touched.password && formik.errors.password && formik.errors.password}
            />

            <Button
              type="submit"
              variant="primary"
              disabled={!formik.isValid || formik.isSubmitting}
              className={styles.button}
              isLoading={formik.isSubmitting}
            >
              Entrar en la plataforma
            </Button>
          </form>
        </Card>
      </div>
    </div>
  )
}

export default React.memo(LoginScreen)
