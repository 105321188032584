import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconChevronRight = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 13 24">
      <path d="M1.252 24c.356 0 .646-.145.883-.392l9.818-10.571c.277-.29.422-.638.422-1.03a1.51 1.51 0 00-.409-1.044L2.148.406A1.18 1.18 0 001.252 0C.553 0 0 .61 0 1.378c0 .377.145.725.369.986l8.961 9.643-8.961 9.63c-.224.26-.369.608-.369.985C0 23.405.553 24 1.252 24z" />
    </svg>
  </i>
)

IconChevronRight.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconChevronRight.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconChevronRight
