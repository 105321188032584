import { SET_AUTH_STATUS, SET_AUTH_DATA } from './actions'
import { CLEAN_ACTION } from '../../store/store'

export const AUTH_STATUS = {
  checking: 1,
  logged: 2,
  loggout: 3,
}

const initialState = {
  status: AUTH_STATUS.checking,
}

const AuthReducer = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case SET_AUTH_STATUS:
      return {
        ...prevState,
        status: payload.status,
      }

    case SET_AUTH_DATA:
      return {
        ...prevState,
        ...payload.data,
      }

    case CLEAN_ACTION:
      return { ...initialState }

    default:
      return prevState
  }
}

export default AuthReducer
