import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconMap = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 30 30">
      <path d="M18.784 29.184V5.586L11.958 1.44a4.016 4.016 0 00-.523-.273A3.326 3.326 0 0010.943 1v23.915l7.009 3.92a3.511 3.511 0 00.832.349zM1.801 28.79c.384 0 .802-.131 1.257-.393l5.857-3.149V1.197a6.72 6.72 0 00-.461.204 9.866 9.866 0 00-.507.265l-6.6 3.784c-.454.252-.792.563-1.014.93C.111 6.75 0 7.197 0 7.72v19.254c0 .585.164 1.034.492 1.347.328.313.764.47 1.31.47zm19.026.333c.081-.02.16-.048.235-.083a2.2 2.2 0 00.235-.129l7.34-4.162c.465-.263.808-.578 1.03-.946.222-.369.333-.81.333-1.325V3.255c0-.595-.166-1.044-.5-1.347-.332-.303-.782-.454-1.347-.454-.403 0-.837.131-1.301.394l-6.025 3.33v23.945z" />
    </svg>
  </i>
)

IconMap.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconMap.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconMap
