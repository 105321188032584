import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconSearch = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 30 30">
      <path d="M27.68 30a2.1 2.1 0 001.06-.265c.31-.177.55-.423.721-.738.172-.315.257-.666.257-1.052a2.23 2.23 0 00-.14-.78 1.722 1.722 0 00-.44-.662l-7.082-7.11.076-.102a12.47 12.47 0 001.036-1.732l.285-.62c.63-1.47.945-3.044.945-4.724 0-1.68-.315-3.256-.945-4.732a12.267 12.267 0 00-2.627-3.895c-1.122-1.121-2.42-2-3.895-2.635C15.456.318 13.878 0 12.199 0c-1.68 0-3.254.318-4.724.953A12.51 12.51 0 003.58 3.588 12.226 12.226 0 00.945 7.475C.315 8.945 0 10.525 0 12.215c0 1.68.315 3.255.945 4.724a12.391 12.391 0 002.627 3.895 12.2 12.2 0 003.895 2.636c1.475.63 3.052.944 4.732.944 1.68 0 3.257-.315 4.732-.944.632-.27 1.232-.586 1.8-.947l.352-.24 7.138 7.12c.133.14.277.254.435.343l.245.113c.254.094.513.141.779.141zm-15.481-8.552c-1.26 0-2.45-.24-3.572-.721a9.256 9.256 0 01-2.95-1.998 9.477 9.477 0 01-1.99-2.942 8.894 8.894 0 01-.72-3.572c0-1.26.24-2.447.72-3.563a9.45 9.45 0 011.99-2.95 9.37 9.37 0 012.95-2.006 8.884 8.884 0 013.572-.73c1.27 0 2.464.244 3.58.73a9.45 9.45 0 014.93 4.956 8.912 8.912 0 01.722 3.563c0 1.271-.24 2.462-.721 3.572a9.477 9.477 0 01-1.989 2.942 9.282 9.282 0 01-2.942 1.998 8.953 8.953 0 01-3.58.72z" />
    </svg>
  </i>
)

IconSearch.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconSearch.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconSearch
