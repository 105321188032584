import moment from 'moment-timezone'
import VehiclesAPI from './api'
import { getVehicleState } from './utils'

export const getVehicles = async ({ companyId }) => {
  const response = await VehiclesAPI.getVehicles({ companyId })

  if (response.status !== 200) {
    throw new Error('Error getting vehicles')
  }

  const vehicles = await response.json()
  return vehicles.map(({ vehiculo, equipo }) => ({
    ...vehiculo,
    kit: equipo,
  }))
}

export const getVehicle = async ({ companyId, vehicleId }) => {
  const response = await VehiclesAPI.getVehicleById({ companyId, vehicleId })

  if (response.status !== 200) {
    throw new Error('Error getting vehicle')
  }

  const vehicle = await response.json()
  return vehicle
}

export const getVehicleLastPosition = async ({ kitId }) => {
  const qp = new URLSearchParams()
  qp.append('limit', 1)
  const response = await VehiclesAPI.getVehicleLastPositions({ kitId, queryParams: qp.toString() })

  if (response.status !== 200) {
    throw new Error('Error getting vehicle last position')
  }

  const positions = await response.json()
  if (positions.length === 0) {
    throw new Error('No position detected')
  }

  const lastPosition = positions[0]
  const { latitud, longitud } = lastPosition

  if (latitud === 0 || longitud === 0) {
    throw new Error('Wrong position detected')
  }

  return lastPosition
}

export const getVehicleLastPositions = async ({ kitId, limit, start, end, ignitionOff = false, replace = false }) => {
  // TODO: handle replace
  const qp = new URLSearchParams()
  limit && qp.append('limit', limit)
  start && qp.append('start', start)
  end && qp.append('end', end)
  const response = await VehiclesAPI.getVehicleLastPositions({
    kitId,
    queryParams: qp.toString(),
  })

  if (response.status !== 200) {
    throw new Error('Error getting vehicle last position')
  }

  const positions = await response.json()
  if (positions.length === 0) {
    throw new Error('No position detected')
  }

  return positions
    .filter((position) => {
      const { latitud, longitud } = position
      if (latitud === 0 || longitud === 0) {
        return false
      }

      if (ignitionOff) {
        return getVehicleState(position) === 'off'
      }

      return true
    })
    .sort((a, b) => moment(b.horagps).diff(moment(a.horagps)))
}

export const updateVehicle = async ({ vehicle }) => {
  const response = await VehiclesAPI.updateVehicle({ vehicle })

  if (response.status !== 200) {
    throw new Error('Bad response status')
  }

  return vehicle
}

export const exportPositions = async ({ kitId, limit, start, end }) => {
  const qp = new URLSearchParams()
  limit && qp.append('limit', limit)
  start && qp.append('start', start)
  end && qp.append('end', end)
  qp.append('f', 'xlsx')

  const response = await VehiclesAPI.getVehicleLastPositions({
    kitId,
    queryParams: qp.toString(),
  })

  if (response.status !== 200) {
    throw new Error('Bad response status')
  }

  const blob = await response.blob()
  const downloadUrl = URL.createObjectURL(blob)
  const filename = response.headers.get('Content-Disposition')
    ? response.headers.get('Content-Disposition').split('=')[1]
    : 'download.xls'
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = downloadUrl
  a.download = filename
  a.click()
  document.body.removeChild(a)

  return { ok: true }
}
