import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import selectStyles from './Select.module.css'
import RSelect, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import IconChevronCircleDown from '../../icons/IconChevronCircleDown'
import Text from '../text/Text'

const customStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      width: '100%',
      height: '36px',
      backgroundColor: 'rgba(var(--clrWhite))',
      padding: '0 12px 0 4px',
      color: 'rgba(var(--clrNtrlMax))',
      fontSize: '14px',
      outline: 'none',
      border: 'solid 1px rgba(var(--clrNtrlLighter))',
      boxSizing: 'border-box',
      boxShadow: '0 0 3px rgba(var(--clrBlack), var(--alphaMin))',
      opacity: state.isDisabled ? '0.6' : '1',
    }
  },

  placeholder: (provided, state) => ({
    ...provided,
    margin: 0,
    color: 'rgba(var(--clrNtrlMax))',
  }),

  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  input: (provided, state) => ({
    ...provided,
    color: 'rgba(var(--clrNtrlMax))',
    margin: 0,
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: 'rgba(var(--clrNtrlMax))',
    margin: 0,
  }),

  menu: (provided, state) => ({
    ...provided,
    marginTop: '6px',
    marginBottom: '6px',
    borderRadius: '3px',
    backgroundColor: 'rgba(var(--clrNtrlLighter))',
    zIndex: 9,
    overflow: 'hidden',
    left: 0,
  }),

  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
  }),

  option: (provided, { isFocused, isSelected, isDisabled, ...rest }) => ({
    ...provided,
    backgroundColor: isDisabled ? null : isFocused || isSelected ? 'rgba(var(--clrNtrlLight))' : 'transparent',
    fontWeight: isSelected ? 'bold' : 'normal',
    ':active': {
      backgroundColor: !isDisabled ? 'rgba(var(--clrPrimary))' : null,
      color: isSelected ? 'rgba(var(--clrNtrlMax))' : 'rgba(var(--clrWhite))',
    },
    ':hover': {
      fontWeight: 'bold',
    },
    color: isSelected ? 'rgba(var(--clrNtrlMax))' : null,
    opacity: isDisabled ? 'var(--alpha)' : '1',
  }),

  noOptionsMessage: (provided, state) => ({
    ...provided,
    color: 'rgba(var(--clrWhite))',
  }),
}

const variantStyles = {
  default: {},

  light: {
    container: (provided, state) => {
      return {
        ...provided,
        borderRadius: '18px',
        width: '100%',
        height: '36px',
        padding: '0 12px 0 4px',
        fontSize: '14px',
        outline: 'none',
        border: 'solid 1px rgba(var(--clrNtrlMin))',
        boxSizing: 'border-box',
        backgroundColor: 'rgba(var(--clrNtrlMin))',
        color: 'rgba(var(--clrNtrlMax))',
      }
    },

    input: (provided, state) => ({
      ...provided,
      color: 'rgba(var(--clrNtrlMax))',
      margin: 0,
    }),

    singleValue: (provided, state) => ({
      ...provided,
      color: 'rgba(var(--clrNtrlMax))',
      margin: 0,
    }),

    option: (provided, { isFocused, isSelected, isDisabled, ...rest }) => ({
      ...provided,
      backgroundColor: isDisabled ? null : isFocused || isSelected ? 'rgba(var(--clrNtrlLighter))' : 'transparent',
      fontWeight: isSelected ? 'bold' : 'normal',
      ':active': {
        ...provided[':active'],
        backgroundColor: !isDisabled ? 'rgba(var(--clrNtrlLighter))' : null,
      },
      opacity: isDisabled ? 'var(--alpha)' : '1',
      color: isSelected ? 'rgba(var(--clrNtrlMax))' : 'rgba(var(--clrWhite))',
    }),

    noOptionsMessage: (provided, state) => ({
      ...provided,
      color: 'rgba(var(--clrWhite))',
    }),
  },
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconChevronCircleDown extraClass={selectStyles.icon} />
    </components.DropdownIndicator>
  )
}

const Select = ({
  options,
  placeholder,
  styles,
  components = {},
  onChange,
  onBlur,
  name,
  isMulti,
  variant = 'default',
  canCreateContent = false,
  async = false,
  loadOptions,
  isSearchable = true,
  disabled = false,
  ...rest
}) => {
  const selectRef = useRef(null)

  const getStyles = useCallback(() => {
    let computedStyles = { ...customStyles, ...variantStyles[variant] }
    if (styles) {
      computedStyles = {
        ...computedStyles,
        ...styles,
      }
    }
    return computedStyles
  }, [styles, variant])

  const renderNoOptionsMessage = useCallback(() => {
    return <Text size="sm" value="No hay opciones disponibles" variant="white" />
  }, [])

  const renderPlaceholder = useCallback(() => {
    return (
      <Text size="sm" variant="max">
        Selecciona
      </Text>
    )
  }, [])

  const handleMenuOpen = useCallback(() => {}, [])

  const handleChange = useCallback(
    (value) => {
      onChange && onChange(name, value)
    },
    [onChange, name],
  )

  const handleBlur = useCallback(() => {
    onBlur && onBlur(name, true)
  }, [onBlur, name])

  const renderCreateLabel = useCallback((inputValue) => {
    return `Crear "${inputValue}"`
  }, [])

  const getComponent = useCallback(() => {
    if (async) {
      return AsyncSelect
    }
    return canCreateContent ? CreatableSelect : RSelect
  }, [async, canCreateContent])

  const Component = getComponent()

  return (
    <div className={selectStyles.wrapper}>
      <Text weight="500" size="sm" className={selectStyles.placeholder} disabled={disabled}>
        {placeholder}
      </Text>
      <Component
        ref={selectRef}
        name={name}
        styles={getStyles()}
        options={options}
        placeholder={renderPlaceholder()}
        components={{ DropdownIndicator, ...components }}
        noOptionsMessage={renderNoOptionsMessage}
        onBlur={handleBlur}
        onChange={handleChange}
        isMulti={isMulti}
        onMenuOpen={handleMenuOpen}
        getOptionValue={(option) => option.value}
        isSearchable={isSearchable || canCreateContent}
        formatCreateLabel={renderCreateLabel}
        className={selectStyles[variant]}
        loadOptions={loadOptions}
        isDisabled={disabled}
        {...rest}
      />
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array,
}

export default React.memo(Select)
