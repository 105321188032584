import React from 'react'
import styles from './Card.module.css'
import c from 'classnames'

const Card = ({ className, children, onClick }) => {
  return React.createElement(
    onClick ? 'button' : 'div',
    {
      className: c(styles.container, className),
      onClick: onClick,
    },
    children,
  )
}

export default React.memo(Card)
