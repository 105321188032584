import FetchHelper from '../FetchHelper'

class AuthAPI {
  static login({ user, pass }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('login', {
      method: 'POST',
      headers: generateHeaders({ headers: {}, isAuthenticated: false }),
      body: {
        user,
        pass,
      },
    })
  }

  static refreshToken() {
    const { fetch, generateHeaders } = FetchHelper
    return fetch('login/me', {
      headers: generateHeaders({ headers: {} }),
    })
  }
}

export default AuthAPI
