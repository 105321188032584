import bus_idle from '../components/assets/markers/icon-marker-bus-idle.svg'
import bus_on from '../components/assets/markers/icon-marker-bus-on.svg'
import bus_off from '../components/assets/markers/icon-marker-bus-off.svg'

import car_idle from '../components/assets/markers/icon-marker-car-idle.svg'
import car_on from '../components/assets/markers/icon-marker-car-on.svg'
import car_off from '../components/assets/markers/icon-marker-car-off.svg'

import jeep_idle from '../components/assets/markers/icon-marker-jeep-idle.svg'
import jeep_on from '../components/assets/markers/icon-marker-jeep-on.svg'
import jeep_off from '../components/assets/markers/icon-marker-jeep-off.svg'

import motorbike_idle from '../components/assets/markers/icon-marker-motorbike-idle.svg'
import motorbike_on from '../components/assets/markers/icon-marker-motorbike-on.svg'
import motorbike_off from '../components/assets/markers/icon-marker-motorbike-off.svg'

import truck_idle from '../components/assets/markers/icon-marker-truck-idle.svg'
import truck_on from '../components/assets/markers/icon-marker-truck-on.svg'
import truck_off from '../components/assets/markers/icon-marker-truck-off.svg'

import van_idle from '../components/assets/markers/icon-marker-van-idle.svg'
import van_on from '../components/assets/markers/icon-marker-van-on.svg'
import van_off from '../components/assets/markers/icon-marker-van-off.svg'

const markers = {
  bus: {
    width: 60,
    height: 25,
    state: {
      idle: bus_idle,
      on: bus_on,
      off: bus_off,
    },
  },
  car: {
    width: 60,
    height: 20,
    state: {
      idle: car_idle,
      on: car_on,
      off: car_off,
    },
  },
  jeep: {
    width: 55,
    height: 28,
    state: {
      idle: jeep_idle,
      on: jeep_on,
      off: jeep_off,
    },
  },
  motorbike: {
    width: 36,
    height: 28,
    state: {
      idle: motorbike_idle,
      on: motorbike_on,
      off: motorbike_off,
    },
  },
  truck: {
    width: 49,
    height: 28,
    state: {
      idle: truck_idle,
      on: truck_on,
      off: truck_off,
    },
  },
  van: {
    width: 60,
    height: 24,
    state: {
      idle: van_idle,
      on: van_on,
      off: van_off,
    },
  },
}

export default markers
