import React from 'react'
import styles from './VehicleTooltip.module.css'
import { Tooltip } from 'react-leaflet'
import Card from 'components/atoms/card/Card'
import c from 'classnames'
import Text from 'components/atoms/text/Text'
import moment from 'moment-timezone'
import IconTag from '../../../../icons/IconTag'
moment.locale('es')

const VehicleTooltip = ({ className, vehicle, offset, isLastValidPosition, ...rest }) => {
  const lastUpdate = React.useMemo(() => {
    const now = moment()
    const positionUpdated = moment(vehicle.kit.hora)

    const diffInMinutes = now.diff(positionUpdated, 'minutes')
    if (diffInMinutes < 60) {
      return `${diffInMinutes}min`
    }

    const diffInHours = Math.floor(diffInMinutes / 60)
    if (diffInHours < 24) {
      return `${diffInHours}h`
    }

    const diffInDays = Math.floor(diffInHours / 24)
    return `${diffInDays} días`
  }, [vehicle.kit.hora])

  return (
    <Tooltip permanent direction="top" offset={offset} {...rest}>
      <Card className={c(styles.container, isLastValidPosition && styles.warning, className)}>
        <div className={styles.header}>
          <IconTag variant="white" size="small" className={styles.icon} />
          <Text variant="white" size="m" weight="600">
            {vehicle?.etiqueta || 'No disponible'}
          </Text>
        </div>
        <Text variant="white">Matrícula {vehicle?.matricula || 'No disponible'}</Text>

        <Text variant="white">Actualizado hace {lastUpdate}</Text>

        {isLastValidPosition && <Text variant="white">Mostrando última posición válida</Text>}
      </Card>
    </Tooltip>
  )
}

export default React.memo(VehicleTooltip)
