import FetchHelper from '../FetchHelper'

class VehiclesAPI {
  static getVehicles({ companyId }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`empresas/${companyId}/vehiculos`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getVehicleById({ companyId, vehicleId }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`empresas/${companyId}/vehiculos/${vehicleId}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static getVehicleLastPositions({ kitId, queryParams }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`equipos/${kitId}/posicion?${queryParams}`, {
      method: 'GET',
      headers: generateHeaders({ headers: {} }),
    })
  }

  static updateVehicle({ vehicle }) {
    const { fetch, generateHeaders } = FetchHelper
    return fetch(`vehiculos/${vehicle.idvehiculo}`, {
      method: 'PUT',
      headers: generateHeaders({ headers: {} }),
      body: vehicle,
    })
  }
}

export default VehiclesAPI
