import { useQueries, useQuery } from '@tanstack/react-query'
import React from 'react'
import { getVehicleLastPosition, getVehicleLastPositions, getVehicles } from './queries'
import moment from 'moment-timezone'
import { filterStops, getRouteDistance, getVehicleState } from './utils'

export const useVehicles = ({ companyId }, options = {}) => {
  return useQuery({
    queryKey: ['vehicles', companyId],
    queryFn: () => getVehicles({ companyId }),
    enabled: !!companyId,
    refetchInterval: 20000,
    ...options,
  })
}

export const useVehicle = ({ companyId, vehicleId }, options = {}) => {
  const { data: vehicles, ...rest } = useVehicles({ companyId })

  const vehicle = React.useMemo(() => {
    if (!vehicles) return null
    return vehicles.find((v) => {
      return parseInt(v.idvehiculo) === parseInt(vehicleId)
    })
  }, [vehicleId, vehicles])

  return { data: vehicle, ...rest }
}

export const useVehiclePositions = ({ companyId, vehicleId, ...params }, options = {}) => {
  const { data: vehicle } = useVehicle({ companyId, vehicleId })
  return useQuery({
    queryKey: ['vehicles', companyId, vehicleId, 'positions', params ?? {}],
    queryFn: () => getVehicleLastPositions({ companyId, kitId: vehicle?.kit?.idequipo, ...params }),
    enabled: !!companyId && !!vehicleId,
    ...options,
  })
}

export const useVehiclesLastPosition = ({ companyId, vehiclesIds, ...params }, options = {}) => {
  const { data: vehicles } = useVehicles({ companyId })
  return useQueries({
    queries: vehiclesIds.map((vehicleId) => ({
      queryKey: ['vehicles', companyId, vehicleId, 'positions', params ?? {}],
      queryFn: async () => {
        const vehicle = vehicles.find((v) => {
          return parseInt(v.idvehiculo) === parseInt(vehicleId)
        })
        try {
          const position = await getVehicleLastPosition({ companyId, kitId: vehicle?.kit?.idequipo, ...params })
          return position
        } catch (error) {
          if (vehicle?.kit?.ultLatitudValida === 0 || vehicle?.kit?.ultLongitudValida === 0) {
            throw new Error('No position detected')
          }

          return {
            latitud: vehicle?.kit?.ultLatitudValida,
            longitud: vehicle?.kit?.ultLongitudValida,
            isLastValidPosition: true,
          }
        }
      },
      ...options,
    })),
    enabled: !!companyId && vehiclesIds?.length > 0,
  })
}

export const useVehicleStops = ({ companyId, vehicleId }) => {
  const { data: vehicle } = useVehicle({ companyId, vehicleId })
  const params = React.useMemo(() => {
    return {
      start: moment().startOf('day').valueOf(),
      end: moment().endOf('day').valueOf(),
    }
  }, [])
  const { data: positions, ...rest } = useQuery({
    queryKey: ['vehicles', companyId, vehicleId, 'positions', params],
    queryFn: () => getVehicleLastPositions({ companyId, kitId: vehicle?.kit?.idequipo, ...params }),
    enabled: !!companyId && !!vehicleId,
  })

  const stops = React.useMemo(() => {
    return filterStops({ positions })?.length
  }, [positions])

  return { data: stops, ...rest }
}

export const useVehicleTravelDistance = ({ companyId, vehicleId }) => {
  const { data: vehicle } = useVehicle({ companyId, vehicleId })
  const params = React.useMemo(() => {
    return {
      start: moment().startOf('day').valueOf(),
      end: moment().endOf('day').valueOf(),
    }
  }, [])
  const { data: positions, ...rest } = useQuery({
    queryKey: ['vehicles', companyId, vehicleId, 'positions', params],
    queryFn: () => getVehicleLastPositions({ companyId, kitId: vehicle?.kit?.idequipo, ...params }),
    enabled: !!companyId && !!vehicleId,
  })

  const disatance = React.useMemo(() => {
    if (!positions) return 0
    return Math.round(parseFloat(positions[0]?.distanciatotal - positions[positions.length - 1]?.distanciatotal))
  }, [positions])

  return { data: disatance, ...rest }
}

export const usePositionsFilteredByState = ({ state, positions }) => {
  const filtered = React.useMemo(() => {
    if (!positions) return []

    return positions.filter((position) => getVehicleState(position) === state)
  }, [positions, state])

  return filtered
}

export const usePositionsStops = ({ positions }) => {
  const stops = React.useMemo(() => {
    return filterStops({ positions })
  }, [positions])

  return stops
}

export const usePositionsCoordinates = ({ positions }) => {
  const coordinates = React.useMemo(() => {
    if (!positions || positions.length === 0) {
      return []
    }

    const coordinates = positions.map((position) => {
      return {
        latitude: position.latitud / 100000,
        longitude: position.longitud / 100000,
        place: position.posicion,
      }
    })

    return coordinates
  }, [positions])

  return coordinates
}

export const usePositionsKms = ({ positions }) => {
  const km = React.useMemo(() => {
    if (!positions || positions.length === 0) {
      return 0
    }

    const km = positions[0]?.distanciatotal - positions[positions.length - 1]?.distanciatotal
    return km
  }, [positions])

  return km
}

export const usePositionsRoutes = ({ positions, order = 'asc' }) => {
  const routes = React.useMemo(() => {
    if (!positions || positions.length === 0) {
      return []
    }

    let detectedRoutes = []
    let lastState = null
    let route = []
    positions.forEach((position) => {
      const currentState = getVehicleState(position)

      if (currentState !== lastState) {
        if (route.length > 0) {
          detectedRoutes.push(route)
          route = []
        }
      }

      route.push(position)
      lastState = currentState
    })

    if (route.length > 0) {
      detectedRoutes.push(route)
    }

    detectedRoutes = detectedRoutes
      .map((route) => {
        return route.map((position) => {
          return {
            latitude: position.latitud / 100000,
            longitude: position.longitud / 100000,
            place: position.posicion,
            ...position,
          }
        })
      })
      .filter((route) => getRouteDistance(route) > 0)

    return order === 'asc' ? detectedRoutes.reverse() : detectedRoutes
  }, [order, positions])

  return routes
}
