import AppService from '../AppService'
import FetchHelper from '../FetchHelper'
import CookieService from '../cookies/service'
import { setStatus, setAuthData } from './actions'
import { AUTH_STATUS } from './reducer'
import * as selectors from './selectors'
import AuthAPI from './api'
import { CLEAN_ACTION } from '../../store/store'

const checkCookieChanged = () => {
  const readCookie = CookieService.readCookie()
  if (document.visibilityState === 'visible' && CookieService.currentCookie !== readCookie) {
    window.location.reload()
  }
}

class AuthServiceClass extends AppService {
  constructor() {
    super()
    document.addEventListener('visibilitychange', checkCookieChanged)
    this._selectors = selectors
  }

  get selectors() {
    return this._selectors
  }

  set selectors(_) {
    return
  }

  login({ user, pass }) {
    return new Promise(async (resolve, reject) => {
      const { setAuthToken } = FetchHelper
      const response = await AuthAPI.login({ user, pass })

      try {
        const { user } = await response.json()
        const token = response.headers.get('X-AuthToken')
        if (!token) {
          return reject({ success: false })
        }

        setAuthToken({ authToken: token })
        CookieService.setCookie(token)
        const { dispatch } = this
        dispatch(setStatus({ status: AUTH_STATUS.logged }))
        dispatch(setAuthData({ data: user }))
        resolve({ success: true, user })
      } catch (error) {
        console.info(error)
        reject({ success: false, error })
      }
    })
  }

  loginSilently() {
    const { dispatch } = this
    dispatch(setStatus({ status: AUTH_STATUS.checking }))

    return new Promise(async (resolve, reject) => {
      const token = CookieService.readCookie()
      if (!token) {
        dispatch(setStatus({ status: AUTH_STATUS.loggout }))
        return reject({ success: false, error: new Error('No cookie found') })
      }

      const { setAuthToken } = FetchHelper
      setAuthToken({ authToken: token })
      const response = await AuthAPI.refreshToken()

      if (response.status !== 200) {
        CookieService.deleteCookie()
        dispatch(setStatus({ status: AUTH_STATUS.loggout }))
        return reject({ success: false, error: new Error('Invalid response') })
      }

      try {
        const { token, user } = await response.json()
        setAuthToken({ authToken: token })
        CookieService.setCookie(token)
        dispatch(setAuthData({ data: user }))
        dispatch(setStatus({ status: AUTH_STATUS.logged }))
        resolve({ success: true })
      } catch (error) {
        console.info(error)
        CookieService.deleteCookie()
        dispatch(setStatus({ status: AUTH_STATUS.loggout }))
        reject({ success: false, error })
      }
    })
  }

  logout() {
    const { setAuthToken } = FetchHelper
    CookieService.deleteCookie()
    setAuthToken({ authToken: null })
    this.dispatch({ type: CLEAN_ACTION })
  }
}

const AuthService = new AuthServiceClass()
export default AuthService
