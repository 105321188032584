const routes = {
  home: '/',
  vehicle: {
    details: '/:vehicleId/details',
    edit: '/:vehicleId/edit',
  },
  login: '/login',
}

export const generateRoute = (route, params) => {
  let generatedRoute = route
  params.forEach(({ name, value }) => {
    generatedRoute = generatedRoute.replace(`:${name}`, value)
  })
  return generatedRoute
}

export default routes
