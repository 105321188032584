import React from 'react'
import styles from './Button.module.css'
import Loader from '../loader/Loader'
import c from 'classnames'

const Button = ({ className, variant, inverted, children, isLoading, disabled = false, type = 'button', ...rest }) => {
  return (
    <button
      className={c(styles.button, className, styles[variant], inverted && styles.inverted, disabled && styles.disabled)}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {!isLoading && <span className={styles.label}>{children}</span>} {isLoading && <Loader />}
    </button>
  )
}

export default React.memo(Button)
