import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table'
import Text from 'components/atoms/text/Text'
import moment from 'moment-timezone'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getCompanyId } from '../../../../modules/auth/selectors'
import { useVehicle, useVehiclePositions } from '../../../../modules/vehicles/hooks'
import { useExportPositions } from '../../../../modules/vehicles/mutations'
import Button from '../../../atoms/button/Button'
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '../../../atoms/table/Table'
import styles from './ExportLocationsModal.module.css'

moment.locale('es')

const ExportLocationsModal = ({ vehicleId, searchParams, maxPostions, onConfirm, hideModal }) => {
  const companyId = useSelector(getCompanyId)
  const { data: fetchedPositions } = useVehiclePositions({ companyId, vehicleId, ...searchParams })
  const positions = React.useMemo(() => {
    if (!fetchedPositions || fetchedPositions.length === 0) {
      return []
    }
    return fetchedPositions.slice(0, maxPostions)
  }, [fetchedPositions, maxPostions])
  const { data: vehicle } = useVehicle({ companyId, vehicleId })

  const { mutate: exportPositions, isLoading: isFetching } = useExportPositions()

  const columns = React.useMemo(() => {
    return [
      {
        header: 'Hora',
        accessorKey: 'horagps',
        cell: (info) => {
          return (
            <Text size="sm" truncate>
              {moment.utc(info.getValue()).tz('Europe/Madrid').format('DD/MM/YY, HH:mm')}
            </Text>
          )
        },
      },
      {
        header: 'Latitud',
        accessorKey: 'latitud',
        cell: (info) => {
          return (
            <Text size="sm" truncate>
              {info.getValue() / 100000}
            </Text>
          )
        },
      },
      {
        header: 'Longitud',
        accessorKey: 'longitud',
        cell: (info) => {
          return (
            <Text size="sm" truncate>
              {info.getValue() / 100000}
            </Text>
          )
        },
      },
      {
        header: 'Posición',
        accessorKey: 'posicion',
        cell: (info) => {
          return (
            <Text size="sm" truncate>
              {info.getValue()}
            </Text>
          )
        },
      },
    ]
  }, [])

  const tableInstance = useReactTable({
    columns,
    data: positions,
    columnResizeMode: 'onChange',
    initialState: { pageSize: 50, pageIndex: 0 },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })
  const { getHeaderGroups, getState, setPageIndex } = tableInstance

  const handleExportClick = useCallback(() => {
    exportPositions(
      {
        kitId: vehicle?.kit?.idequipo,
        ...searchParams,
      },
      {
        onSuccess: () => {
          hideModal?.()
        },
      },
    )
  }, [exportPositions, vehicle, searchParams, hideModal])

  const columnSizeVars = React.useMemo(() => {
    const headers = tableInstance.getFlatHeaders()
    const colSizes: { [key: string]: number } = {}
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]
      colSizes[`--header-${header.id}-size`] = header.getSize()
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize()
    }
    return colSizes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getState().columnSizingInfo, getState().columnSizing])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text size="xl" align="left">
          Exporta las posiciones del vehículo
        </Text>
      </div>

      <div className={styles.content}>
        <Table
          className={styles.table}
          borderless
          style={{
            ...columnSizeVars, //Define column sizes on the <table> element
            // width: tableInstance.getTotalSize(),
          }}
        >
          <TableHead>
            {getHeaderGroups().map((headerGroup) => (
              <TableRow sticky key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => {
                  return (
                    <TableCell
                      className={styles.headcell}
                      key={header.id}
                      style={{ width: `calc(var(--header-${header?.id}-size) * 1px)` }}
                    >
                      <Text size="sm" weight="500" variant="white">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>

                      {/* <TableColumnResizer
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        isResizing={header.column.getIsResizing()}
                      /> */}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableContent table={tableInstance} />
        </Table>
        <TablePagination
          count={positions.length}
          page={getState().pagination.pageIndex}
          rowsPerPage={getState().pagination.pageSize}
          onPageChange={setPageIndex}
        />
      </div>

      <div className={styles.actions}>
        <Button className={styles.action} inverted variant="dark" onClick={hideModal} disabled={isFetching}>
          Cancelar
        </Button>

        <Button
          className={styles.action}
          variant="primary"
          onClick={handleExportClick}
          disabled={isFetching}
          isLoading={isFetching}
        >
          Exportar
        </Button>
      </div>
    </div>
  )
}

const TableContent = React.memo(
  ({ table }) => {
    return (
      <TableBody>
        {table.getRowModel().rows.map((row) => {
          return (
            <TableRow className={styles.tableRow} key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              })}
            </TableRow>
          )
        })}
      </TableBody>
    )
  },
  (prev, next) => prev.table.options.data === next.table.options.data,
)

export default React.memo(ExportLocationsModal)
