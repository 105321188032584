import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconRefresh = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 30 30">
      <path d="M15.307 30c1.708 0 3.305-.317 4.793-.951a12.353 12.353 0 003.93-2.649 12.353 12.353 0 002.648-3.93c.634-1.487.951-3.085.951-4.792 0-.38-.122-.693-.366-.937-.243-.243-.556-.365-.936-.365-.361 0-.654.122-.878.365-.225.244-.337.557-.337.937a9.72 9.72 0 01-2.86 6.951 9.87 9.87 0 01-3.125 2.1c-1.18.503-2.454.754-3.82.754a9.666 9.666 0 01-3.812-.754 9.762 9.762 0 01-3.124-2.1 9.642 9.642 0 01-2.093-3.131 9.802 9.802 0 01-.746-3.82c0-1.366.248-2.644.746-3.834a9.617 9.617 0 012.085-3.124 9.836 9.836 0 013.117-2.1 9.586 9.586 0 013.813-.761c.79 0 1.522.05 2.195.153l.449.08L14.005 12a1.497 1.497 0 00-.27.402 1.114 1.114 0 00-.096.461c0 .352.12.647.359.886s.529.358.87.358c.371 0 .664-.117.878-.35l5.693-5.723c.263-.273.395-.585.395-.936a1.28 1.28 0 00-.395-.937L15.746.395C15.532.132 15.234 0 14.854 0c-.332 0-.617.124-.856.373a1.252 1.252 0 00-.359.9c0 .166.032.325.095.476.064.151.149.285.256.402l3.482 3.422-.248-.044a13.078 13.078 0 00-1.931-.144c-1.708 0-3.305.315-4.793.944a12.142 12.142 0 00-3.915 2.634 12.387 12.387 0 00-2.634 3.922C3.317 14.373 3 15.971 3 17.678c0 1.707.32 3.305.959 4.793A12.577 12.577 0 006.6 26.4a12.183 12.183 0 003.915 2.649c1.487.634 3.085.951 4.792.951z" />
    </svg>
  </i>
)

IconRefresh.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconRefresh.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconRefresh
