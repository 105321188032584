import React, { useCallback, useMemo } from 'react'
import styles from './Table.module.css'
import c from 'classnames'
import ButtonIcon from '../../atoms/buttonIcon/ButtonIcon'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import Text from '../../atoms/text/Text'

const Table = React.memo(({ id, className, borderless = false, hovered = false, ...rest }) => {
  return (
    <table
      id={id}
      className={c(styles.table, borderless && styles.borderless, hovered && styles.hovered, className)}
      {...rest}
    />
  )
})

const TableHead = React.memo(({ className, children, ...rest }) => {
  return (
    <thead className={c(styles.tablehead, className)} {...rest}>
      {React.Children.map(children, (child) => child && React.cloneElement(child, { isTableHead: true }))}
    </thead>
  )
})

const TableBody = React.memo(({ className, ...rest }) => {
  return <tbody className={c(styles.tablebody, className)} {...rest} />
})

const TableRow = React.memo(
  ({ className, isTableHead = false, children, fill = false, sticky = false, onClick, ...rest }) => {
    return (
      <tr
        className={c(styles.tr, fill && styles.fill, onClick && styles.clickable, className)}
        onClick={onClick}
        {...rest}
      >
        {React.Children.map(children, (child) => child && React.cloneElement(child, { isTableHead, sticky }))}
      </tr>
    )
  },
)

const TableCell = React.memo(
  ({ className, isTableHead = false, align = 'left', sticky = false, children, ...rest }) => {
    return React.createElement(isTableHead ? 'th' : 'td', {
      className: c(styles.cell, isTableHead ? styles.th : styles.td, styles[align], sticky && styles.sticky, className),
      children,
      ...rest,
    })
  },
)

const TablePagination = React.memo(({ className, count, rowsPerPage, page, onPageChange }) => {
  const handleOnNextPageClick = useCallback(() => {
    onPageChange?.(page + 1)
  }, [page, onPageChange])

  const handleOnPrevPageClick = useCallback(() => {
    onPageChange?.(page - 1)
  }, [page, onPageChange])

  const numberOfPages = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage])

  const isNextPageDisabled = useMemo(() => page === numberOfPages - 1, [page, numberOfPages])

  const isPrevPageDisabled = useMemo(() => page === 0, [page])

  const paginationInfo = useMemo(() => {
    let currentPageLastElement = 1 * (page + 1) * rowsPerPage
    const currentPageFirstElement = currentPageLastElement - rowsPerPage

    if (currentPageLastElement > count) {
      currentPageLastElement = count
    }
    return `${currentPageFirstElement}-${currentPageLastElement} de ${count}`
  }, [count, rowsPerPage, page])

  return (
    <div className={c(styles.tablePagination, className)}>
      <Text size="m">{paginationInfo}</Text>

      <div className={styles.navigationWrapper}>
        <ButtonIcon
          icon={<FiChevronLeft size="24px" color="rgb(4, 9, 65)" />}
          onClick={handleOnPrevPageClick}
          disabled={isPrevPageDisabled}
          tabIndex={-1}
          aria-label="Ir a la página anterior"
          title="Ir a la página anterior"
        />

        <ButtonIcon
          icon={<FiChevronRight size="24px" color="rgb(4, 9, 65)" />}
          onClick={handleOnNextPageClick}
          disabled={isNextPageDisabled}
          tabIndex={0}
          aria-label="Ir a la siguiente página"
          title="Ir a la siguiente página"
        />
      </div>
    </div>
  )
})

const TableColumnResizer = React.memo(({ onDoubleClick, onMouseDown, onTouchStart, isResizing }) => {
  return (
    <div
      {...{
        onDoubleClick,
        onMouseDown,
        onTouchStart,
        className: `${styles.resizer} ${isResizing ? styles.isResizing : ''}`,
      }}
    />
  )
})

export { Table, TableHead, TableBody, TableRow, TableCell, TableColumnResizer, TablePagination }
