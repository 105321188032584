import React, { useRef, useEffect } from 'react'
import styles from './Map.module.css'
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import usePrevious from '../../../hooks/usePrevious'
import L from 'leaflet'

const defaultBounds = new L.LatLngBounds([
  [43.083219, -7.3041187],
  [37.72268, -0.6519217],
])
const Map = ({ width, height, bounds = defaultBounds, center, mapFocused, children, ...rest }) => {
  const map = useRef()
  const prevBounds = usePrevious(bounds)

  useEffect(() => {
    if (!map.current) {
      return
    }

    setTimeout(() => {
      map.current.invalidateSize?.(true)
    }, 600)
  }, [map, mapFocused])

  useEffect(() => {
    if (!map.current) {
      return
    }
    if (prevBounds && bounds && prevBounds.getCenter().equals(bounds.getCenter())) {
      return
    }
    map.current.fitBounds?.(bounds)
  }, [map, bounds, prevBounds])

  return (
    <div style={{ width: `${width}px`, height: `${height}px` }} className={styles.wrapper}>
      <MapContainer ref={map} bounds={bounds} zoom={13} zoomControl={false} scrollWheelZoom {...rest}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {children}
      </MapContainer>
    </div>
  )
}

export default React.memo(Map)
