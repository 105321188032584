import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconCheckbox = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 48 48">
      <path d="M18.226 48c1.283 0 2.275-.56 3.004-1.653L47.242 5.048c.525-.856.758-1.535.758-2.214C48 1.15 46.863 0 45.171 0c-1.166 0-1.837.443-2.566 1.594L18.11 41.034 5.453 24.353c-.729-1.003-1.458-1.417-2.537-1.417C1.196 22.936 0 24.117 0 25.8c0 .708.292 1.476.875 2.214l14.318 18.332c.875 1.122 1.78 1.653 3.033 1.653z" />
    </svg>
  </i>
)

IconCheckbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconCheckbox.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconCheckbox
