import L from 'leaflet'
import React from 'react'
import { CircleMarker, LayerGroup, Polyline, Tooltip, ZoomControl } from 'react-leaflet'
import { useDebouncedCallback } from 'use-debounce'
import Map from '../../../../../components/molecules/map/Map.js'
import VehicleMarker from '../../../../../components/molecules/vehicleMarker/VehicleMarker.js'
import {
  usePositionsCoordinates,
  usePositionsRoutes,
  usePositionsStops,
} from '../../../../../modules/vehicles/hooks.js'
import { generateColor } from '../../../../../utils'
import styles from './MapRoutes.module.css'

const startRouteStyles = {
  color: 'green',
}

const endRouteStyles = {
  color: 'red',
}

const colors = []

for (let i = 0; i < 100; i++) {
  colors.push(generateColor())
}

const MapRoutes = ({ width, height, vehicle, positions, hiddenRoutes = [] }) => {
  const [tooltipContent, setTooltipContent] = React.useState(null)
  const [highlightedRoute, setHighlightedRoute] = React.useState(null)
  const stops = usePositionsStops({ positions, state: 'off' })
  const positionsCoordinates = usePositionsCoordinates({ positions })
  const stopsCoordinates = usePositionsCoordinates({ positions: stops })
  const routes = usePositionsRoutes({ positions })

  const polylineCoordinates = React.useMemo(() => {
    return positionsCoordinates.map((position) => [position.latitude, position.longitude])
  }, [positionsCoordinates])

  const mapBounds = React.useMemo(() => {
    const bounds = polylineCoordinates
    if (!bounds || bounds.length === 0) {
      return new L.LatLngBounds([
        [43.083219, -7.3041187],
        [37.72268, -0.6519217],
      ])
    }
    return new L.LatLngBounds(bounds)
  }, [polylineCoordinates])

  const setTooltipContentDebounced = useDebouncedCallback(({ latitude, longitude }) => {
    const sortedPositions = positionsCoordinates.sort((a, b) => {
      const distanceA = L.latLng(a.latitude, a.longitude).distanceTo(L.latLng(latitude, longitude))
      const distanceB = L.latLng(b.latitude, b.longitude).distanceTo(L.latLng(latitude, longitude))
      return distanceA - distanceB
    })

    if (sortedPositions.length === 0) {
      return
    }
    setTooltipContent(sortedPositions[0]?.place)
  }, 600)
  const handleMouseOverPolyline = React.useCallback(
    ({ evt, index }) => {
      const { latlng } = evt
      const { lat, lng } = latlng
      setTooltipContentDebounced({ latitude: lat, longitude: lng })
      setHighlightedRoute(index)
    },
    [setTooltipContentDebounced],
  )

  const handleMouseOutPolyline = React.useCallback((evt) => {
    setHighlightedRoute(null)
    setTooltipContent('Cargando...')
  }, [])

  const shouldRenderStartAndEndPoints = positionsCoordinates.length > 0

  const startPosition = React.useMemo(() => {
    if (positionsCoordinates.length === 0) {
      return null
    }
    return [positionsCoordinates[0]?.latitude, positionsCoordinates[0]?.longitude]
  }, [positionsCoordinates])

  const endPosition = React.useMemo(() => {
    if (positionsCoordinates.length === 0) {
      return null
    }
    return [
      positionsCoordinates[positionsCoordinates.length - 1]?.latitude,
      positionsCoordinates[positionsCoordinates.length - 1]?.longitude,
    ]
  }, [positionsCoordinates])

  return (
    <Map width={width} height={height} bounds={mapBounds}>
      <ZoomControl position="bottomright" />
      <LayerGroup>
        {shouldRenderStartAndEndPoints && (
          <>
            <CircleMarker key="start-circle" center={startPosition} radius={30} pathOptions={endRouteStyles} />

            <CircleMarker key="end-circle" center={endPosition} radius={30} pathOptions={startRouteStyles} />
          </>
        )}

        {stopsCoordinates?.map((stop, idx) => {
          const { latitude, longitude } = stop
          return <CircleMarker key={`${idx}-${latitude}-${longitude}`} center={[latitude, longitude]} radius={30} />
        })}
      </LayerGroup>

      {routes?.map((route, index) => {
        if (hiddenRoutes?.has(index)) {
          return null
        }
        const coordinates = route.map((position) => [position.latitude, position.longitude])
        const isHighlightedRoute = index === highlightedRoute
        return (
          <Polyline
            positions={coordinates}
            eventHandlers={{
              mouseover: (evt) => handleMouseOverPolyline({ evt, route, index }),
              mouseout: (evt) => handleMouseOutPolyline({ evt, route, index }),
            }}
            pathOptions={{ weight: isHighlightedRoute ? 6 : 3 }}
          >
            <Tooltip sticky>
              <span className={styles.tooltip}>{tooltipContent}</span>
            </Tooltip>
          </Polyline>
        )
      })}
      {positions.length > 0 && <VehicleMarker vehicle={vehicle} position={positions[0]} />}
    </Map>
  )
}

export default React.memo(MapRoutes)
