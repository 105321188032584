import React from 'react'
import styles from './Logo.module.css'
import atl from './assets/logo_atl_europa.jpg'
import c from 'classnames'

const Logo = ({ className, ...rest }) => {
  return <img src={atl} alt="ATL Logo" className={c(styles.logo, className)} />
}

export default React.memo(Logo)
