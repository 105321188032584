import c from 'classnames'
import Select from 'components/atoms/select/Select'
import Text from 'components/atoms/text/Text'
import FormField from 'components/molecules/formField/FormField'
import FormRow from 'components/molecules/formRow/FormRow'
import moment from 'moment-timezone'
import React, { useCallback, useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table'
import ButtonIcon from '../../../../components/atoms/buttonIcon/ButtonIcon'
import Checkbox from '../../../../components/atoms/checkbox/Checkbox'
import Input from '../../../../components/atoms/input/Input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '../../../../components/atoms/table/Table'
import IconDetails from '../../../../components/icons/IconDetails'
import IconGoogleMaps from '../../../../components/icons/IconGoogleMaps'
import IconMap from '../../../../components/icons/IconMap'
import H from '../../../../components/molecules/h/H'
import routes, { generateRoute } from '../../../../config/routes'
import { useTrackAssistant } from '../../../../hooks/trackAssistant/useTrackAssistant'
import { getCompanyId } from '../../../../modules/auth/selectors'
import { useVehicles } from '../../../../modules/vehicles/hooks'
import { getVehicleState, isValidPosition } from '../../../../modules/vehicles/utils'
import { VehicleState } from './components/vehicleState/VehicleState'
import styles from './Content.module.css'
import { AiOutlineWarning } from 'react-icons/ai'
import useSearchHistory from '../../../../hooks/useSearchHistory'

const SEARCH_BY_PLATE = 1
const SEARCH_BY_TAG = 2

const filterOptions = [
  {
    label: 'Matrícula',
    value: SEARCH_BY_PLATE,
  },
  {
    label: 'Etiqueta',
    value: SEARCH_BY_TAG,
  },
]

const Content = ({ className, toggleMap, ...rest }) => {
  const history = useHistory()
  const [filterBy, setFilterBy] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const companyId = useSelector(getCompanyId)
  const { data: vehicles } = useVehicles({ companyId })

  const { isTracked, addTrackingVehicle, removeTrackingVehicle } = useTrackAssistant()

  const { searchHistory, addSearch } = useSearchHistory()

  const handleTrackChange = React.useCallback(
    (name, value, isChecked) => {
      if (isChecked) {
        addTrackingVehicle({ vehicleId: value })
      } else {
        removeTrackingVehicle({ vehicleId: value })
      }
    },
    [addTrackingVehicle, removeTrackingVehicle],
  )

  const data = React.useMemo(() => {
    return vehicles
      .filter((vehicle) => {
        if (filterBy === SEARCH_BY_PLATE) {
          return vehicle.matricula.toLowerCase().includes(searchTerm.replace(/\s/g, '').toLowerCase())
        }
        if (filterBy === SEARCH_BY_TAG) {
          return vehicle.etiqueta.toLowerCase().includes(searchTerm.trim().toLowerCase())
        }
        return true
      })
      .map((vehicle) => {
        const lastKnownPosition = {
          latitude: vehicle?.kit?.latitud / 100000,
          longitude: vehicle?.kit?.longitud / 100000,
          isLastValidPosition: false,
        }

        if (
          !isValidPosition({
            latitud: lastKnownPosition?.latitude,
            longitud: lastKnownPosition?.longitude,
          })
        ) {
          lastKnownPosition.latitude = vehicle?.kit?.ultLatitudValida / 100000
          lastKnownPosition.longitude = vehicle?.kit?.ultLongitudValida / 100000
          lastKnownPosition.isLastValidPosition = true
        }

        const positionLabel =
          vehicle.kit.posicion === 'Sin referencia de posición'
            ? isValidPosition(vehicle.kit)
              ? `${vehicle.kit.latitud / 100000}, ${vehicle.kit.longitud / 100000}`
              : 'Sin señal GPS'
            : vehicle.kit.posicion

        return {
          id: vehicle.idvehiculo,
          isTracked: isTracked(vehicle.idvehiculo),
          state: getVehicleState(vehicle?.kit),
          label: vehicle.etiqueta,
          plate: vehicle.matricula,
          timestamp: moment(vehicle.kit.hora).unix(),
          datetime: moment(vehicle.kit.hora).format('DD/MM/YY, HH:mm'),
          lastPosition: positionLabel,
          position: lastKnownPosition,
          totalKM: `${new Intl.NumberFormat('es-ES').format(vehicle.distanciatotal)} km` ?? 'N/A',
          distanciatotal: vehicle.distanciatotal,
        }
      })
  }, [isTracked, vehicles, filterBy, searchTerm])

  const columns = React.useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'isTracked',
        cell: ({ row }) => {
          const { id, isTracked } = row.original
          return (
            <Checkbox
              className={styles.checkbox}
              isChecked={isTracked}
              name={id}
              value={id}
              onChange={handleTrackChange}
            />
          )
        },
        invertSorting: false,
        sortingFn: (rowA, rowB, columnId) => {
          if (rowA.original[columnId] > rowB.original[columnId]) {
            return -1
          } else {
            return 1
          }
        },
      },
      {
        header: '',
        accessorKey: 'state',
        cell: (info) => {
          return <VehicleState state={info.getValue()} />
        },
        invertSorting: true,
      },
      {
        header: 'Etiqueta',
        accessorKey: 'label',
        cell: (info) => {
          return (
            <Text size="sm" truncate>
              {info.getValue()}
            </Text>
          )
        },
      },
      {
        header: 'Matrícula',
        accessorKey: 'plate',
        cell: (info) => {
          return (
            <Text size="sm" truncate>
              {info.getValue()}
            </Text>
          )
        },
      },
      {
        header: 'Hora',
        accessorKey: 'datetime',
        cell: (info) => {
          return (
            <Text size="sm" truncate>
              {info.getValue()}
            </Text>
          )
        },
        sortingFn: (rowA, rowB) => {
          return rowA.original.timestamp - rowB.original.timestamp
        },
      },
      {
        header: 'Última posición',
        accessorKey: 'lastPosition',
        cell: ({ getValue, row }) => {
          const rowData = row.original
          const { position } = rowData
          const props = {}
          if (position.isLastValidPosition) {
            props.variant = 'warning'
          }
          return (
            <div className={styles.postionCell}>
              {position.isLastValidPosition && (
                <AiOutlineWarning
                  className={styles.warningIcon}
                  color="rgba(251, 150, 35)"
                  title="Última posición conocida"
                />
              )}
              <Text size="sm" {...props}>
                {getValue()}
              </Text>
            </div>
          )
        },
      },
      {
        header: 'Km totales',
        accessorKey: 'totalKM',
        cell: (info) => {
          return (
            <Text size="sm" truncate>
              {info.getValue()}
            </Text>
          )
        },
        sortingFn: (rowA, rowB, columnId) => {
          return rowA.original.distanciatotal - rowB.original.distanciatotal
        },
      },
    ]
  }, [handleTrackChange])

  const tableInstance = useReactTable({
    columns,
    data,
    enableSortingRemoval: false,
    autoResetSortBy: false,
    initialState: { pagination: { pageSize: 50, pageIndex: 0 } },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  const { getHeaderGroups, getRowModel, getState, setPageIndex } = tableInstance

  const handleFilterByChange = useCallback((name, option) => {
    setFilterBy(option.value)
    setSearchTerm('')
  }, [])

  const handleSearchChange = useCallback(
    (evt) => {
      setSearchTerm(evt?.target?.value)
      addSearch(evt?.target?.value)
    },
    [addSearch],
  )

  const handleDetailsClick = useCallback(
    (id) => {
      return () => history.push(generateRoute(routes.vehicle.details, [{ name: 'vehicleId', value: id }]))
    },
    [history],
  )

  const handleOpenGoogleMaps = useCallback((position) => {
    return () => {
      window.open(
        `https://maps.google.com/?q=${position.latitude},${position.longitude}`,
        '_blank',
        'noopener,noreferrer',
      )
    }
  }, [])

  return (
    <div className={c(styles.container, className)}>
      <H>Mi flota</H>
      <div className={styles.actionsWrapper}>
        <FormRow className={styles.filtersWrapper}>
          <FormField
            field={
              <Select
                type="text"
                placeholder="Filtrar por..."
                value={filterOptions.find(({ value }) => filterBy === value)}
                options={filterOptions}
                onChange={handleFilterByChange}
              />
            }
          />

          <FormField
            field={
              <Input
                name="search"
                placeholder="Escribe aquí para filtrar"
                disabled={!filterBy}
                value={searchTerm}
                onChange={handleSearchChange}
                toUpperCase={false}
                suggestions={searchHistory}
              />
            }
          />
        </FormRow>

        <ButtonIcon variant="dark" icon={<IconMap variant="white" />} onClick={toggleMap} />
      </div>
      <div className={styles.content}>
        <Table className={styles.table} borderless>
          <TableHead>
            {getHeaderGroups().map((headerGroup) => (
              <TableRow sticky key={headerGroup.id}>
                {headerGroup.headers.map((header, idx) => {
                  const isTrackedColumn = idx === 0
                  const isStateColumn = idx === 1
                  const column = header.column
                  const isSortedDesc = column.getNextSortingOrder() === 'asc'
                  return (
                    <TableCell className={styles.headCell} key={header.id} onClick={column.getToggleSortingHandler()}>
                      {!isTrackedColumn && !isStateColumn && (
                        <>
                          <Text size="sm" weight="500" variant="white">
                            {flexRender(column.columnDef.header, header.getContext())}
                          </Text>
                          {column.getIsSorted() ? (
                            isSortedDesc ? (
                              <BsChevronDown size={12} color="#ffffff" />
                            ) : (
                              <BsChevronUp size={12} color="#ffffff" />
                            )
                          ) : (
                            ''
                          )}
                        </>
                      )}

                      {isStateColumn && (
                        <VehicleState state={column.getIsSorted() ? (isSortedDesc ? 'off' : 'on') : ''} />
                      )}

                      {isTrackedColumn && (
                        <Checkbox
                          className={c(styles.checkbox, styles.pointerNone)}
                          isChecked={column.getIsSorted() ? (isSortedDesc ? false : true) : false}
                          name="isTracked"
                          value="isTracked"
                        />
                      )}
                    </TableCell>
                  )
                })}
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {getRowModel().rows.map((row) => {
              const { id, position } = row.original
              return (
                <TableRow className={styles.tableRow} key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    )
                  })}
                  <TableCell>
                    <span className={styles.actions}>
                      <ButtonIcon
                        title="Abrir en Google Maps"
                        icon={<IconGoogleMaps />}
                        onClick={handleOpenGoogleMaps(position)}
                      />
                      <ButtonIcon
                        title="Ver detalles"
                        icon={<IconDetails variant="dark" />}
                        onClick={handleDetailsClick(id)}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          count={vehicles.length}
          page={getState().pagination.pageIndex}
          rowsPerPage={getState().pagination.pageSize}
          onPageChange={setPageIndex}
        />
      </div>
    </div>
  )
}

export default React.memo(Content)
