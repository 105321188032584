import React, { useState, useCallback, useEffect } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import moment from 'moment-timezone'
import c from 'classnames'
import 'moment/locale/es'
import './Datepicker.module.css'

const Datepicker = ({ mode = 'single', start, end, onRangeSelected, disabled = false, ...rest }) => {
  const [range, setRange] = useState({
    from: start,
    to: end,
  })
  const modifiers = {
    after: moment().toDate(),
    before: moment().subtract(5, 'months').toDate(),
  }

  const handleDaySelected = useCallback(
    (day) => {
      if (mode === 'single') {
        setRange({ from: day, to: day })
        return
      }
      const newRange = DateUtils.addDayToRange(day, range)
      setRange(newRange)
    },
    [range, mode],
  )

  useEffect(() => {
    if (range.from && range.to) {
      onRangeSelected && onRangeSelected(range)
    }
  }, [onRangeSelected, range])

  useEffect(() => {
    setRange({ from: null, to: null })
  }, [mode])

  const { from, to } = range
  return (
    <DayPicker
      className={c('datepicker', disabled && 'disabled')}
      mode={mode}
      localeUtils={MomentLocaleUtils}
      locale="es"
      disabledDays={modifiers}
      onDayClick={handleDaySelected}
      modifiers={{ start: from, end: to }}
      selectedDays={[from, { from, to }]}
      {...rest}
    />
  )
}

export default React.memo(Datepicker)
