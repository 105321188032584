import React from 'react'
import c from 'classnames'
import styles from './Text.module.css'

export const ALIGN = ['left', 'center', 'right']
export const VARIANTS = [
  'max',
  'dark',
  'light',
  'lighter',
  'min',
  'primary',
  'secondary',
  'info',
  'positive',
  'warning',
  'negative',
  'white',
  'black',
  'panda',
  'pandaDark',
  'flamingo',
  'cocodrile',
  'pig',
  'koala',
  'parrot',
  'parrotLight',
  'octupus',
]
export const SIZES = ['xs', 's', 'sm', 'm', 'ml', 'l', 'xl', 'xxl']
export const TRANSFORM = [null, 'capitalize', 'uppercase', 'lowercase', 'inherit']

export const ELEMENTS = ['p', 'span']

const Text = ({
  className,
  children,
  element = ELEMENTS[0],
  size = SIZES[2],
  variant = VARIANTS[0],
  weight = 400,
  align = ALIGN[0],
  transform = TRANSFORM[0],
  truncate,
  textStyle,
  disabled = false,
  ...rest
}) => {
  return React.createElement(
    element,
    {
      className: c(
        styles.font,
        className,
        size && styles[`size-${size}`],
        variant && styles[variant],
        align && styles[align],
        styles[`weight-${weight}`],
        transform && styles[`trans-${transform}`],
        truncate && styles.truncate,
        textStyle && styles[textStyle],
        disabled && styles.disabled,
      ),
      ...rest,
    },
    children,
  )
}

export default Text
