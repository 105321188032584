import React from 'react'
import styles from './SearchButton.module.css'
import c from 'classnames'
import ButtonIcon from 'components/atoms/buttonIcon/ButtonIcon'
import IconSearch from 'components/icons/IconSearch'
import { useModal } from '../../../../../hooks/modal/ModalProvider'
import SearchLocationsModal from '../../../../../components/molecules/modals/searchLocations/SearchLocationsModal'
import { useParams } from 'react-router-dom'

const SearchButton = ({ className, searchParams, onSearchChange, ...rest }) => {
  const { showModal } = useModal()
  const { vehicleId } = useParams()

  const openSearchLocationsModal = React.useCallback(() => {
    showModal({
      Component: <SearchLocationsModal vehicleId={vehicleId} searchParams={searchParams} onConfirm={onSearchChange} />,
      className: styles.modal,
      dismissible: false,
    })
  }, [onSearchChange, searchParams, showModal, vehicleId])

  return (
    <ButtonIcon
      className={c(styles.button, className)}
      variant="white"
      icon={<IconSearch variant="dark" />}
      onClick={openSearchLocationsModal}
      {...rest}
    />
  )
}

export default React.memo(SearchButton)
