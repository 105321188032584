import React from 'react'
import styles from './InfoRow.module.css'
import c from 'classnames'
import Text from 'components/atoms/text/Text'

const InfoRow = ({ className, label, content, children, ...rest }) => {
  const sanitizeContent = (content) => {
    if (!content) {
      return null
    }
    return (String(content) || '').trim()
  }

  return (
    <div className={c(styles.container, className)}>
      <Text weight="500" size="sm" className={styles.label}>
        {label}
      </Text>

      {!children && <Text>{sanitizeContent(content) || 'No disponible'}</Text>}

      {children}
    </div>
  )
}

export default React.memo(InfoRow)
