import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Loader from '../../components/atoms/loader/Loader'
import Menu from '../../components/molecules/menu/Menu'
import routes from '../../config/routes'
import { getCompanyId } from '../../modules/auth/selectors'
import { useVehicles } from '../../modules/vehicles/hooks'
import HomeScreen from '../home/HomeScreen'
import DetailsScreen from '../vehicle/details/DetailsScreen'
import EditScreen from '../vehicle/edit/EditScreen'
import styles from './DashboardScreen.module.css'

const DashboardScreen = ({ ...rest }) => {
  const companyId = useSelector(getCompanyId)
  const { isLoading: isLoadingVehicles } = useVehicles({ companyId })
  const initialized = !isLoadingVehicles

  return (
    <div className={styles.container}>
      <Menu />

      <SwitchTransition mode="out-in">
        <CSSTransition
          key={initialized}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false)
          }}
          classNames="fade"
        >
          <div className={styles.contentAnimatableWrapper}>
            {initialized && (
              <Fragment>
                <Route exact path={routes.home} component={HomeScreen} />
                <Route exact path={routes.vehicle.details} component={DetailsScreen} />
                <Route exact path={routes.vehicle.edit} component={EditScreen} />
              </Fragment>
            )}
            {!initialized && (
              <div className={styles.loaderWrapper}>
                <Loader variant="primary" />
              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

export default React.memo(DashboardScreen)
