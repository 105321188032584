import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconLogout = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 48 48">
      <path d="M4 1.714v44.572C4 47.232 4.768 48 5.714 48H40c.947 0 1.714-.768 1.714-1.714v-3.429h-3.428v1.714H7.429V3.43h30.857v5.14h3.428V1.714C41.714.768 40.947 0 40 0H5.714C4.768 0 4 .768 4 1.714zm27.926 29.64l5.657-5.64H19.429v-3.428h18.154l-5.657-5.64 2.434-2.435 8.571 8.572a1.714 1.714 0 010 2.434L34.36 33.79l-2.434-2.435z" />
    </svg>
  </i>
)

IconLogout.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconLogout.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconLogout
