import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { ItmosQueryClient } from '../ItmosQueryClient'
import { exportPositions, updateVehicle } from './queries'

export const useEditVehicleMutation = () => {
  return useMutation(updateVehicle, {
    onError: () => {
      toast(`Opps! No se ha podido actualizar el vehículo en este momento`, {
        type: toast.TYPE.ERROR,
      })
    },
    onSuccess: (_, variables) => {
      ItmosQueryClient.invalidateQueries(['vehicles', variables.companyId])
    },
  })
}

export const useExportPositions = () => {
  return useMutation(exportPositions, {
    onError: () => {
      toast(`Opps! No se han podido exportar las posiciones del vehículo`, {
        type: toast.TYPE.ERROR,
      })
    },
    onSuccess: () => {
      toast('Se han exportado los datos a formato xls', {
        type: toast.TYPE.INFO,
      })
    },
  })
}
