import React from 'react'
import styles from './SplashScreen.module.css'
import Logo from '../../components/atoms/logo/Logo'

const SplashScreen = ({ ...rest }) => {
  return (
    <div className={styles.container}>
      <Logo />
    </div>
  )
}

export default React.memo(SplashScreen)
