import * as Yup from 'yup'

const vehicleSchema = Yup.object().shape({
  matricula: Yup.string().required('Debes introducir la matrícula del vehículo'),
  modelo: Yup.string().required('Debes introducir el modelo del vehículo'),
  etiqueta: Yup.string().required('Debes introducir la etiqueta del vehículo'),
  numbastidor: Yup.string().required('Debes introducir el número de bastidor del vehículo'),
  descripcion: Yup.string(),
})

export default vehicleSchema
