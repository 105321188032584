import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconList = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 30 30">
      <path d="M1.939 8.124c.549 0 1.017-.192 1.405-.575a1.89 1.89 0 00.583-1.397c0-.549-.195-1.017-.583-1.406a1.915 1.915 0 00-1.405-.582c-.549 0-1.017.194-1.405.582A1.915 1.915 0 00-.05 6.152c0 .548.194 1.014.583 1.397.388.383.856.575 1.405.575zm26.708-.668c.373 0 .683-.127.932-.38.248-.254.372-.562.372-.924 0-.363-.124-.67-.372-.924a1.25 1.25 0 00-.932-.38H8.663c-.383 0-.7.124-.948.372a1.264 1.264 0 00-.372.932c0 .362.124.67.372.924.249.253.565.38.948.38h19.984zM1.939 17.083c.549 0 1.017-.191 1.405-.574a1.89 1.89 0 00.583-1.398 1.9 1.9 0 00-.583-1.39 1.915 1.915 0 00-1.405-.582c-.549 0-1.017.194-1.405.583a1.897 1.897 0 00-.583 1.39c0 .548.194 1.014.583 1.397.388.383.856.574 1.405.574zm26.708-.667c.373 0 .683-.127.932-.38.248-.254.372-.562.372-.925 0-.362-.124-.67-.372-.924a1.25 1.25 0 00-.932-.38H8.663c-.383 0-.7.127-.948.38a1.271 1.271 0 00-.372.924c0 .363.124.67.372.924.249.254.565.38.948.38h19.984zM1.939 26.058c.549 0 1.017-.194 1.405-.582.388-.388.583-.856.583-1.405a1.9 1.9 0 00-.583-1.39A1.915 1.915 0 001.94 22.1c-.549 0-1.017.194-1.405.582a1.897 1.897 0 00-.583 1.39c0 .549.194 1.017.583 1.405.388.388.856.582 1.405.582zm26.708-.683a1.268 1.268 0 001.304-1.304c0-.362-.124-.67-.372-.924a1.25 1.25 0 00-.932-.38H8.663c-.383 0-.7.126-.948.38a1.271 1.271 0 00-.372.924c0 .373.124.683.372.932.249.248.565.372.948.372h19.984z" />
    </svg>
  </i>
)

IconList.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconList.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconList
