import Text from 'components/atoms/text/Text'
import moment from 'moment-timezone'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../../../atoms/button/Button'
import Checkbox from '../../../atoms/checkbox/Checkbox'
import Datepicker from '../../datepicker/Datepicker'
import FormField from '../../formField/FormField'
import RadioGroup from '../../radioGroup/RadioGroup'
import TimePicker from '../../timePicker/TimePicker'
import styles from './SearchLocationsModal.module.css'
import options, { CUSTOM, LAST_30_MINUTES, mapOptionToRange } from './model'

const SearchLocationsModal = ({ searchParams, onConfirm, hideModal, vehicleId }) => {
  const [option, setOption] = useState(LAST_30_MINUTES)
  const [range, setRange] = useState(mapOptionToRange(LAST_30_MINUTES))
  const [fromTime, setFromTime] = useState({ value: moment().startOf('day').unix() })
  const [toTime, setToTime] = useState({ value: moment().endOf('day').unix() })
  const [ignitionOff, setIgnitionOff] = useState(searchParams?.ignitionOff ?? false)
  const [rangeMode, setRangeMode] = useState(false)

  const handleRangeSelected = useCallback((range) => {
    setRange({
      from: moment(range.from),
      to: moment(range.to),
    })
  }, [])

  const handleOptionChange = useCallback((_, value) => {
    setOption(value)
    setRange(mapOptionToRange(value))
  }, [])

  const handleFromTimeChange = useCallback((_, optionTime) => {
    setFromTime(optionTime)
  }, [])

  const handleToTimeChange = useCallback((_, optionTime) => {
    setToTime(optionTime)
  }, [])

  const handleSearchClick = useCallback(() => {
    const startMoment = range.from
    const endMoment = range.to

    if (option === CUSTOM) {
      startMoment.hour(moment.unix(fromTime.value).hour())
      startMoment.minute(moment.unix(fromTime.value).minute())
      startMoment.second(0)

      endMoment.hour(moment.unix(toTime.value).hour())
      endMoment.minute(moment.unix(toTime.value).minute())
      endMoment.second(59)
    }

    toast('Puede que la consulta tarde un poco más de lo habitual debido al gran volumen de datos del vehículo', {
      type: toast.TYPE.INFO,
    })

    onConfirm?.({
      searchParams: {
        start: startMoment.valueOf(),
        end: endMoment.valueOf(),
        ignitionOff,
      },
      maxRenderedPositions: -1,
    })

    hideModal?.()
  }, [range.from, range.to, option, ignitionOff, onConfirm, hideModal, fromTime.value, toTime.value])

  const isSearchDisabled = option === CUSTOM && !range?.from && !range?.to

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Text size="xl" align="left">
          Busca las últimas posiciones registradas
        </Text>

        <Text align="left" extraClass={styles.description}>
          Debido a la gran cantidad de datos acumulados por los vehículos, recomendamos no hacer consultas que superen
          los{' '}
          <Text element="span" weight="600">
            dos días
          </Text>
        </Text>
      </div>

      <div className={styles.content}>
        <div className={styles.options}>
          <RadioGroup options={options} name="searchOption" value={option} onChange={handleOptionChange} />
        </div>
        <div className={styles.searchWrapper}>
          <Datepicker
            mode={rangeMode ? 'range' : 'single'}
            onRangeSelected={handleRangeSelected}
            disabled={option !== CUSTOM}
          />
          <div className={styles.timeWrapper}>
            <FormField
              className={styles.checkbox}
              field={
                <Checkbox
                  className={styles.checkbox}
                  isChecked={rangeMode}
                  name="rangeMode"
                  value="false"
                  onChange={() => {
                    setRangeMode(!rangeMode)
                    setRange({
                      from: null,
                      to: null,
                    })
                  }}
                  label="Rango de fechas"
                  disabled={option !== CUSTOM}
                />
              }
            />

            <FormField
              className={styles.timepickerField}
              size="small"
              field={
                <TimePicker
                  placeholder="Desde"
                  selected={fromTime}
                  name="fromTime"
                  step={1}
                  onChange={handleFromTimeChange}
                  disabled={option !== CUSTOM}
                />
              }
            />

            <FormField
              className={styles.timepickerField}
              field={
                <TimePicker
                  placeholder="Hasta"
                  selected={toTime}
                  name="toTime"
                  step={1}
                  onChange={handleToTimeChange}
                  disabled={option !== CUSTOM}
                />
              }
            />

            <FormField
              className={styles.checkbox}
              field={
                <Checkbox
                  className={styles.checkbox}
                  isChecked={ignitionOff}
                  name="ignition"
                  value="true"
                  onChange={() => setIgnitionOff(!ignitionOff)}
                  label="Ver paradas"
                />
              }
            />
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <Button className={styles.action} inverted variant="dark" onClick={hideModal}>
          Cancelar
        </Button>

        <Button className={styles.action} variant="primary" onClick={handleSearchClick} disabled={isSearchDisabled}>
          Buscar
        </Button>
      </div>
    </div>
  )
}

export default React.memo(SearchLocationsModal)
