import store from '../store/index'

export const CLEAR = 'CLEAR'

class AppService {
  get selectors() {
    return this._selectors
  }

  set selectors(_) {
    return
  }

  dispatch = (action) => {
    store.dispatch(action)
  }

  getState = () => {
    return store.getState()
  }

  clearStore = () => {
    store.dispatch({ type: CLEAR })
  }
}

export default AppService
