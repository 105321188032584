import React, { useCallback } from 'react'
import styles from './RefreshButton.module.css'
import c from 'classnames'
import ButtonIcon from 'components/atoms/buttonIcon/ButtonIcon'
import IconRefresh from 'components/icons/IconRefresh'
import { useTrackAssistant } from '../../../../../hooks/trackAssistant/useTrackAssistant'

const RefreshButton = ({ className, onRefresh, ...rest }) => {
  const { setSearchParams } = useTrackAssistant()

  const handleRefreshClick = useCallback(() => {
    setSearchParams(null)
    onRefresh && onRefresh(true)
  }, [setSearchParams, onRefresh])

  return (
    <ButtonIcon
      className={c(styles.button, className)}
      variant="white"
      icon={<IconRefresh variant="dark" />}
      onClick={handleRefreshClick}
    />
  )
}

export default React.memo(RefreshButton)
