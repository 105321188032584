import Button from 'components/atoms/button/Button'
import ButtonIcon from 'components/atoms/buttonIcon/ButtonIcon'
import Card from 'components/atoms/card/Card'
import Input from 'components/atoms/input/Input'
import FormField from 'components/molecules/formField/FormField'
import FormRow from 'components/molecules/formRow/FormRow'
import H from 'components/molecules/h/H'
import { Field, Form, Formik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import IconChevronLeft from '../../../components/icons/IconChevronLeft'
import FormEffect from '../../../components/molecules/formEffect/FormEffect'
import useDirtyForm from '../../../hooks/dirtyForm/useDirtyForm'
import useUnsavedContentModal from '../../../hooks/modal/useUnsavedContentModal'
import { getCompanyId } from '../../../modules/auth/selectors'
import { useVehicle } from '../../../modules/vehicles/hooks'
import { useEditVehicleMutation } from '../../../modules/vehicles/mutations'
import styles from './EditScreen.module.css'
import vehicleSchema from './schema/vehicle'

const EditScreen = () => {
  const history = useHistory()
  const { vehicleId } = useParams()
  const companyId = useSelector(getCompanyId)
  const { data: vehicle } = useVehicle({ companyId, vehicleId })
  const [isDirty, onFormGetsDirty, clean] = useDirtyForm()
  const { showConfirmModal } = useUnsavedContentModal()
  const { mutate: updateVehicle } = useEditVehicleMutation()

  const goBack = useCallback(() => {
    if (!isDirty) {
      history.goBack()
      return
    }

    showConfirmModal({
      onConfirm: () => {
        history.goBack()
      },
    })
  }, [isDirty, history, showConfirmModal])

  const handleSubmit = useCallback(
    (values) => {
      return new Promise((resolve, reject) => {
        const updatedVehicle = { idvehiculo: vehicle.idvehiculo, ...values }
        delete updatedVehicle.kit
        updateVehicle(
          { companyId, vehicle: updatedVehicle },
          {
            onSuccess: () => {
              toast('Se ha actualizado la información del vehículo correctamente', {
                type: toast.TYPE.SUCCESS,
              })
              clean()
              history.goBack()
            },
          },
        )
      })
    },
    [vehicle?.idvehiculo, updateVehicle, companyId, clean, history],
  )

  useEffect(() => {
    if (!vehicle) {
      goBack()
    }
  }, [vehicle, goBack])

  if (!vehicle) {
    return
  }

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <ButtonIcon className={styles.backButton} icon={<IconChevronLeft />} onClick={goBack} />
        <H>Actualización de los datos del vehículo {vehicle.matricula}</H>
      </div>

      <Formik
        initialValues={{
          matricula: vehicle.matricula || '',
          modelo: vehicle.modelo || '',
          etiqueta: vehicle.etiqueta || '',
          numbastidor: vehicle.numbastidor || '',
          descripcion: vehicle.descripcion || '',
        }}
        validationSchema={vehicleSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isValid, isSubmitting, ...formikProps }) => {
          return (
            <Form>
              <FormEffect onFormGetsDirty={onFormGetsDirty} />
              <FormRow>
                <Field name="matricula">
                  {({ field, form, meta: { touched, error } }) => {
                    return (
                      <FormField
                        field={<Input type="text" placeholder="Matrícula" {...field} />}
                        error={touched && error && error}
                      />
                    )
                  }}
                </Field>

                <Field name="modelo">
                  {({ field, form, meta: { touched, error } }) => {
                    return (
                      <FormField
                        field={<Input type="text" placeholder="Modelo" {...field} />}
                        error={touched && error && error}
                      />
                    )
                  }}
                </Field>

                <Field name="etiqueta">
                  {({ field, form, meta: { touched, error } }) => {
                    return (
                      <FormField
                        field={<Input type="text" placeholder="Etiqueta" {...field} />}
                        error={touched && error && error}
                      />
                    )
                  }}
                </Field>

                <Field name="numbastidor">
                  {({ field, form, meta: { touched, error } }) => {
                    return (
                      <FormField
                        field={<Input type="text" placeholder="Número de bastidor" {...field} />}
                        error={touched && error && error}
                      />
                    )
                  }}
                </Field>

                <Field name="descripcion">
                  {({ field, form, meta: { touched, error } }) => {
                    return (
                      <FormField
                        size="large"
                        field={<Input type="text" placeholder="Descripción" {...field} />}
                        error={touched && error && error}
                      />
                    )
                  }}
                </Field>
              </FormRow>

              <FormRow>
                <Button
                  className={styles.submitButton}
                  variant="primary"
                  type="submit"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                >
                  Guardar
                </Button>
              </FormRow>
            </Form>
          )
        }}
      </Formik>
    </Card>
  )
}

export default React.memo(EditScreen)
