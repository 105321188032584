import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import AutoSizer from 'react-virtualized-auto-sizer'
import ButtonIcon from '../../../components/atoms/buttonIcon/ButtonIcon'
import Tag from '../../../components/atoms/tag/Tag'
import IconChevronLeft from '../../../components/icons/IconChevronLeft'
import { getCompanyId } from '../../../modules/auth/selectors'
import {
  useVehicle,
  useVehiclePositions,
  useVehicleStops,
  useVehicleTravelDistance,
} from '../../../modules/vehicles/hooks'
import styles from './DetailsScreen.module.css'
import ExportButton from './components/exportButton/ExportButton'
import MapRoutes from './components/mapRoutes/MapRoutes'
import RefreshButton from './components/refreshButton/RefreshButton'
import SearchButton from './components/searchButton/SearchButton'
import SearchFile from './components/searchFile/SearchFile'
import VehicleFile from './components/vehicleFile/VehicleFile'
import { HIDE_ROUTE, SET_SEARCH_PARAMS, SHOW_ROUTE, initialState, reducer } from './reducer'

const DetailsScreen = ({ ...rest }) => {
  const history = useHistory()
  const { vehicleId } = useParams()
  const companyId = useSelector(getCompanyId)
  const { data: vehicle } = useVehicle({ companyId, vehicleId })
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const {
    data: fetchedPositions,
    isLoading: isLoadingPositions,
    isError,
    refetch: searchPositions,
  } = useVehiclePositions({ companyId, vehicleId, ...state?.searchParams })
  const { data: stopsToday } = useVehicleStops({ companyId, vehicleId })
  const { data: travelKm } = useVehicleTravelDistance({ companyId, vehicleId })

  const positions = React.useMemo(() => {
    if (!fetchedPositions || fetchedPositions.length === 0) {
      return []
    }
    let end = state?.maxRenderedPositions
    if (state?.maxRenderedPositions < 0) {
      end = undefined
    }
    return fetchedPositions.slice(0, end)
  }, [fetchedPositions, state?.maxRenderedPositions])

  const goBack = React.useCallback(() => {
    history.goBack()
  }, [history])

  const handleSearchChange = React.useCallback((payload) => {
    dispatch({ type: SET_SEARCH_PARAMS, payload })
  }, [])

  const handleHideRouteChange = React.useCallback(
    ({ index }) => {
      const isAlreadyHidden = state?.hiddenRoutes?.has(index)
      dispatch({ type: isAlreadyHidden ? SHOW_ROUTE : HIDE_ROUTE, payload: { index } })
    },
    [state?.hiddenRoutes],
  )

  React.useEffect(() => {
    if (isError && !isLoadingPositions) {
      toast(`Opps! No se encontraron datos de geolocalización para el vehículo con identificador ${vehicleId}`, {
        type: toast.TYPE.ERROR,
        toastId: vehicleId,
      })
    }
  }, [isError, isLoadingPositions, vehicleId])

  return (
    <div className={styles.container}>
      <AutoSizer>
        {({ width, height }) => {
          return (
            <MapRoutes
              width={width}
              height={height}
              vehicle={vehicle}
              positions={positions}
              hiddenRoutes={state?.hiddenRoutes}
            />
          )
        }}
      </AutoSizer>

      <ButtonIcon
        className={styles.backButton}
        variant="white"
        icon={<IconChevronLeft variant="dark" />}
        onClick={goBack}
      />

      <div className={styles.header}>
        <div className={styles.trackinfo}>
          <Tag className={styles.leyend}>
            <span className={styles.stop}>Parada</span>
            <span className={styles.start}>Inicio de ruta</span>
            <span className={styles.end}>Final de ruta</span>
          </Tag>

          {!isNaN(travelKm) && <Tag>{`${travelKm}Km recorridos hoy`}</Tag>}

          <Tag>{`${stopsToday} paradas hoy`}</Tag>
        </div>

        <div className={styles.actions}>
          <SearchButton
            className={styles.actionButton}
            searchParams={state?.searchParams}
            onSearchChange={handleSearchChange}
          />

          <ExportButton
            className={styles.actionButton}
            maxPostions={state?.maxRenderedPositions}
            searchParams={state?.searchParams}
            disabled={isLoadingPositions}
          />

          <RefreshButton className={styles.actionButton} onRefresh={searchPositions} disabled={isLoadingPositions} />
        </div>
      </div>

      {state?.isSearchActive && (
        <SearchFile
          vehicle={vehicle}
          positions={positions}
          hiddenRoutes={state?.hiddenRoutes}
          onHideRouteChange={handleHideRouteChange}
        />
      )}

      <VehicleFile className={state?.isSearchActive && styles.vehicleFile} vehicle={vehicle} />
    </div>
  )
}

export default React.memo(DetailsScreen)
