import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useConfirmModal from './modal/useConfirmModal'

const useLogout = () => {
  const history = useHistory()
  const { showConfirmModal } = useConfirmModal()

  const handleLogutConfirm = useCallback(() => {
    history.replace('/login', {
      clean: true,
    })
  }, [history])

  const handleLogoutClick = useCallback(() => {
    showConfirmModal({
      title: '¿Quieres cerrar sesión?',
      description: 'Si cierras sesión dejarás de tener acceso a la herramienta',
      confirmActionLabel: 'Salir',
      confirmActionVariant: 'negative',
      onConfirm: handleLogutConfirm,
    })
  }, [handleLogutConfirm, showConfirmModal])

  return handleLogoutClick
}

export default useLogout
