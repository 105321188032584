import React, { useCallback } from 'react'
import styles from './Radio.module.css'
import c from 'classnames'

const Radio = ({ className, label, value, isChecked, name, onChange, labelVariant, ...rest }) => {
  const handleInputChange = useCallback(() => {
    onChange && onChange(name, value)
  }, [name, onChange, value])

  return (
    <label className={c(styles.container, className)}>
      <input type="radio" name={name} checked={isChecked} value={value} onChange={handleInputChange} {...rest} />
      <span className={styles.checkmark}></span>
      {label && label}
    </label>
  )
}

export default React.memo(Radio)
