import React, { useCallback } from 'react'
import styles from './Checkbox.module.css'
import Text from '../text/Text'
import IconCheckbox from '../../icons/IconCheckbox'
import c from 'classnames'

const Checkbox = ({ className, label, value, isChecked, name, onChange, labelVariant, ...rest }) => {
  const handleInputChange = useCallback(
    (evt) => {
      onChange && onChange(name, value, evt.target.checked)
    },
    [name, onChange, value],
  )

  return (
    <label className={c(styles.container, className)} htmlFor={name}>
      <input id={name} type="checkbox" name={name} checked={isChecked} onChange={handleInputChange} {...rest} />
      <span className={styles.checkmark}>
        {isChecked && <IconCheckbox className={styles.icon} variant="lighter" size="small" />}
      </span>
      {label && (
        <Text className={styles.label} element="span" variant={labelVariant || 'max'}>
          {label}
        </Text>
      )}
    </label>
  )
}

export default React.memo(Checkbox)
