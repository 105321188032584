import React, { useCallback } from 'react'
import styles from './ConfirmModal.module.css'
import Text from '../../../atoms/text/Text'
import Button from '../../../atoms/button/Button'

const ConfirmModal = ({
  title,
  description,
  confirmActionLabel = 'Aceptar',
  confirmActionVariant = 'primary',
  confirmActionTextVariant = 'white',
  onConfirm,
  onCancel,
  hideModal,
  ...rest
}) => {
  const handleConfirmClick = useCallback(() => {
    hideModal && hideModal()
    onConfirm && onConfirm()
  }, [onConfirm, hideModal])

  const handleCancelClick = useCallback(() => {
    hideModal && hideModal()
    onCancel && onCancel()
  }, [onCancel, hideModal])

  return (
    <div className={styles.container}>
      <div>
        {title && (
          <Text size="xl" align="left">
            {title}
          </Text>
        )}
        {description && (
          <Text align="left" extraClass={styles.description}>
            {description}
          </Text>
        )}
      </div>

      <div className={styles.actionsWrapper}>
        <Button inverted variant="dark" onClick={handleCancelClick}>
          Cancelar
        </Button>

        <Button variant={confirmActionVariant} onClick={handleConfirmClick}>
          {confirmActionLabel}
        </Button>
      </div>
    </div>
  )
}

export default React.memo(ConfirmModal)
