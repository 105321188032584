import React, { useCallback } from 'react'
import { useModal } from './ModalProvider'
import ConfirmModal from '../../components/molecules/modals/confirm/ConfirmModal'

const useConfirmModal = () => {
  const { showModal, hideModal } = useModal()

  const showConfirmModal = useCallback(
    ({ dismissible = false, extraClass, ...options }) => {
      return showModal({
        Component: <ConfirmModal {...options} />,
        dismissible,
        extraClass,
      })
    },
    [showModal],
  )

  const hideConfirmModal = useCallback(() => {
    hideModal()
  }, [hideModal])

  return { showConfirmModal, hideConfirmModal }
}

export default useConfirmModal
