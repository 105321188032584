import React from 'react'
import GaugeChart from 'react-gauge-chart'

const GasGauge = ({ gas, ...rest }) => {
  return (
    <GaugeChart
      id="gas-gauge"
      style={{ width: '140px' }}
      marginInPercent={0.01}
      nrOfLevels={8}
      arcPadding={0.1}
      cornerRadius={3}
      percent={gas}
      colors={['#ef5743', '#bfe28e']}
      hideText
    />
  )
}

export default React.memo(GasGauge)
