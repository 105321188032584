import url from 'url'

class FetchHelperClass {
  _authtoken = null

  setAuthToken = ({ authToken }) => {
    this._authtoken = authToken
  }

  generateHeaders = ({ headers = {}, isAuthenticated = true, useDefaultHeaders = true }) => {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'x-language': 'es',
    }
    let generatedHeaders

    if (useDefaultHeaders) {
      generatedHeaders = {
        ...defaultHeaders,
      }
    }

    generatedHeaders = {
      ...generatedHeaders,
      ...headers,
    }

    if (isAuthenticated) {
      generatedHeaders['authorization'] = `Bearer ${this._authtoken}`
      generatedHeaders['X-AuthToken'] = this._authtoken
    }

    return generatedHeaders
  }

  fetch = (endpoint, { body = null, headers, ...rest }) => {
    const contentType = headers['content-type'] || headers['Content-Type']
    return fetch(url.resolve(process.env.REACT_APP_BASE_HOST, endpoint), {
      ...rest,
      headers,
      body: body && contentType === 'application/json' ? JSON.stringify(body) : body,
    })
  }
}

const FetchHelper = new FetchHelperClass()

export default FetchHelper
