import moment from 'moment-timezone'

export const LAST_30_MINUTES = 1
export const LAST_HOUR = 2
export const LAST_DAY = 3
export const LAST_TWO_DAYS = 4
export const LAST_TWO_WEEKS = 5
export const LAST_MONTH = 6
export const CUSTOM = 7

const options = [
  {
    label: 'Últimos 30 minutos',
    value: LAST_30_MINUTES,
  },
  {
    label: 'Última hora',
    value: LAST_HOUR,
  },
  {
    label: 'Últimas 24 horas',
    value: LAST_DAY,
  },
  {
    label: 'Últimos 2 días',
    value: LAST_TWO_DAYS,
  },
  {
    label: 'Últimos 15 días',
    value: LAST_TWO_WEEKS,
  },
  {
    label: 'Último mes',
    value: LAST_MONTH,
  },
  {
    label: 'Personalizar',
    value: CUSTOM,
  },
]

export const mapOptionToRange = (option) => {
  const map = {
    [LAST_30_MINUTES]: {
      from: moment().subtract('30', 'minutes'),
      to: moment(),
    },
    [LAST_HOUR]: {
      from: moment().subtract('1', 'hours'),
      to: moment(),
    },
    [LAST_DAY]: {
      from: moment().subtract('24', 'hours'),
      to: moment(),
    },
    [LAST_TWO_DAYS]: {
      from: moment().subtract('2', 'days'),
      to: moment(),
    },
    [LAST_TWO_WEEKS]: {
      from: moment().subtract('15', 'days'),
      to: moment(),
    },
    [LAST_MONTH]: {
      from: moment().subtract('1', 'months'),
      to: moment(),
    },
    [CUSTOM]: {},
  }
  return map[option]
}

export default options
