import React from 'react'
import styles from './Tag.module.css'
import c from 'classnames'

const Tag = ({ children, className, extraClass, ...rest }) => {
  return (
    <span className={c(styles.container, className, extraClass)} {...rest}>
      {children}
    </span>
  )
}

export default React.memo(Tag)
