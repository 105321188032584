import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Icon.module.css'

const IconChevronLeft = (props) => (
  <i
    id={props.id}
    className={c(
      styles.icon,
      props.className,
      styles[props.size],
      props.disabled ? styles.disabled : styles[props.variant],
    )}
    style={{ fill: props.fill }}
  >
    <svg viewBox="0 0 14 24">
      <path d="M11.984 24c.767 0 1.35-.595 1.35-1.378a1.46 1.46 0 00-.398-.986L3.28 12.007l9.656-9.643a1.46 1.46 0 00.397-.986C13.333.609 12.751 0 11.984 0c-.383 0-.695.145-.951.406L.454 10.963A1.412 1.412 0 000 12.007c0 .392.156.74.454 1.03l10.579 10.557c.256.261.568.406.951.406z" />
    </svg>
  </i>
)

IconChevronLeft.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'big', 'huge', 'extra-huge']),
  variant: PropTypes.oneOf([
    null,
    'primary',
    'primaryLight',
    'secondary',
    'secondaryLight',
    'info',
    'infoLight',
    'positive',
    'positiveLight',
    'warning',
    'warningLight',
    'negative',
    'negativeLight',
    'max',
    'dark',
    'light',
    'lighter',
    'min',
    'white',
    'black',
  ]),
  fill: PropTypes.string,
  disabled: PropTypes.bool,
}

IconChevronLeft.defaultProps = {
  id: null,
  className: null,
  extraClass: null,
  size: null,
  variant: null,
  fill: null,
  disabled: false,
}

export default IconChevronLeft
