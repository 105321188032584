import React, { useState, useCallback, Fragment } from 'react'
import styles from './HomeScreen.module.css'
import Content from './components/content/Content'
import c from 'classnames'
import { CSSTransition } from 'react-transition-group'
import IconChevronRight from '../../components/icons/IconChevronRight'
import ButtonIcon from '../../components/atoms/buttonIcon/ButtonIcon'
import Card from '../../components/atoms/card/Card'
import AutoSizer from 'react-virtualized-auto-sizer'
import Tracking from './components/tracking/Tracking'

const HomeScreen = () => {
  const [mapFocused, setMapFocused] = useState(false)

  const toggleMap = useCallback(() => {
    setMapFocused(!mapFocused)
  }, [mapFocused])

  return (
    <Fragment>
      <CSSTransition in={mapFocused} timeout={300} classNames="collapse">
        <Card className={c(styles.contentWrapper)}>
          <ButtonIcon className={styles.expandButton} onClick={toggleMap} icon={<IconChevronRight />} />
          <Content className={styles.content} toggleMap={toggleMap}></Content>
        </Card>
      </CSSTransition>
      <div className={styles.map}>
        <AutoSizer>
          {({ width, height }) => {
            return <Tracking width={width} height={height} mapFocused={mapFocused} />
          }}
        </AutoSizer>
      </div>
    </Fragment>
  )
}

export default React.memo(HomeScreen)
