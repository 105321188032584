import c from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'
import Text from '../text/Text'
import styles from './Input.module.css'

const VARIANTS = ['default', 'light']

const Input = ({
  value,
  type = 'text',
  name,
  className,
  wrapperClass,
  invalid,
  icon,
  cleanInput = false,
  onInputClean,
  variant = VARIANTS[0],
  toUpperCase = false,
  onChange,
  autoFocus,
  placeholder,
  suggestions,
  ...rest
}) => {
  const inputElement = useRef(null)

  const focusInput = useCallback(() => {
    inputElement.current && inputElement.current.focus()
  }, [inputElement])

  useEffect(() => {
    if (!inputElement.current) {
      return
    }

    autoFocus && focusInput()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleIconClick = useCallback(() => {
    cleanInput ? onInputClean && onInputClean() : focusInput()
  }, [cleanInput, focusInput, onInputClean])

  const handleChange = useCallback(
    (evt) => {
      if (toUpperCase) {
        evt.target.value = ('' + evt.target.value).toUpperCase()
      }
      onChange && onChange(evt)
    },
    [onChange, toUpperCase],
  )

  return (
    <div className={c(styles.wrapper, wrapperClass)}>
      <Text weight="500" className={styles.placeholder}>
        {placeholder}
      </Text>
      <input
        id={`input-${name}`}
        list={suggestions ? `${name}-list` : null}
        ref={inputElement}
        className={c(styles.input, className, invalid && styles.error, icon && styles.inputWithIcon, styles[variant])}
        name={name}
        value={value}
        type={type}
        onChange={handleChange}
        {...rest}
      />
      {icon && (
        <span className={styles.iconWrapper} onClick={handleIconClick}>
          {icon}
        </span>
      )}

      {suggestions ? (
        <datalist id={`${name}-list`}>
          {suggestions.map((suggestion) => (
            <option key={suggestion} value={suggestion} />
          ))}
        </datalist>
      ) : null}
    </div>
  )
}

export default React.memo(Input)
