import React, { useCallback, useState, useEffect } from 'react'
import moment from 'moment-timezone'
import Select from '../../atoms/select/Select'

export const getCloserTime = (date) => {
  const clone = date.clone()
  let minutes = clone.minute()
  if (minutes > 0 && minutes < 15) {
    minutes = 15
  } else if (minutes < 30) {
    minutes = 30
  } else if (minutes < 45) {
    minutes = 45
  } else {
    minutes = 0
    clone.add(1, 'hour')
  }
  clone.minutes(minutes)
  clone.seconds(0)

  return clone
}

const TimePicker = ({ step = 15, minTime = moment(), onChange, selected, name, ...rest }) => {
  const [options, setOptions] = useState([])

  const getTimeOptions = () => {
    const options = []
    const currentDate = moment.unix(selected.value).startOf('day')
    const endOfDay = moment.unix(selected.value).endOf('day')

    while (!currentDate.isAfter(endOfDay, 'day')) {
      const option = {
        label: currentDate.format('HH:mm'),
        value: currentDate.unix(),
        //isDisabled: isBefore,
      }
      currentDate.add(step, 'minute')
      if (currentDate.isAfter(endOfDay, 'day')) {
        option.value = moment.unix(selected.value).endOf('day').unix()
      }
      options.push(option)
    }

    return options
  }

  useEffect(() => {
    setOptions(getTimeOptions())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateTimeOptions = useCallback(() => {
    options.forEach((option) => {
      const { value } = option
      const optionMoment = moment.unix(value)
      optionMoment.dayOfYear(minTime.dayOfYear())
      optionMoment.month(minTime.month())
      optionMoment.year(minTime.year())
      option.value = optionMoment.unix()

      // TODO think if this is necessary
      /*const isBefore = optionMoment.isBefore(minTime)
      option.isDisabled = isBefore*/
    })
  }, [minTime, options])

  useEffect(() => {
    updateTimeOptions()
  }, [updateTimeOptions])

  return (
    <Select
      options={options}
      onChange={onChange}
      value={options.find(({ value }) => selected.value === value)}
      name={name}
      {...rest}
    />
  )
}

export default React.memo(TimePicker)
