import c from 'classnames'
import ButtonIcon from 'components/atoms/buttonIcon/ButtonIcon'
import Card from 'components/atoms/card/Card'
import Text from 'components/atoms/text/Text'
import H from 'components/molecules/h/H'
import moment from 'moment-timezone'
import React, { useCallback, useState } from 'react'
import IconChevronDown from '../../../../../components/icons/IconChevronDown'
import GasGauge from '../../../../../components/molecules/gasGauge/GasGauge'
import { getVehicleFuel } from '../../../../../modules/vehicles/utils'
import InfoRow from '../infoRow/InfoRow'
import styles from './VehicleFile.module.css'

const VehicleFile = ({ className, vehicle, ...rest }) => {
  const fuelLevel = getVehicleFuel(vehicle)

  const [collapsed, setCollapsed] = useState(true)

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  return (
    <Card className={c(styles.content, collapsed && styles.collapsed, className)}>
      <div className={styles.header}>
        <Text weight="600" variant="white" size="l">
          {vehicle?.matricula}
        </Text>

        <ButtonIcon
          onClick={toggleCollapsed}
          icon={<IconChevronDown className={styles.collapseIcon} variant="white" />}
        />
      </div>

      <div className={styles.infoContent}>
        <div className={styles.infoHeader}>
          <H size="m">Datos del vehículo</H>
        </div>

        <InfoRow label="Matrícula" content={vehicle?.matricula} />

        <InfoRow label="Modelo" content={vehicle?.modelo} />

        <InfoRow label="Etiqueta" content={vehicle?.etiqueta} />

        <InfoRow label="Número de bastidor" content={vehicle?.numbastidor} />

        <InfoRow label="Descripción" content={vehicle?.descripcion} />

        <InfoRow label="Km recorridos totales" content={vehicle?.distanciatotal} />

        <InfoRow label="Km recorridos hoy" content={vehicle?.distanciaparcial} />

        <InfoRow label="Última posición" content={vehicle?.kit?.posicion} />

        <InfoRow
          label="Hora de la última posición"
          content={moment(vehicle?.kit?.hora).format('DD/MM/YYYY, HH:mm:ss')}
        />

        <InfoRow label="Nivel depósito de combustible">
          {fuelLevel && (
            <div className={styles.gasWrapper}>
              <GasGauge gas={fuelLevel / 100} />
              <Text size="xl" className={styles.fuelPercent}>
                {fuelLevel}%
              </Text>
            </div>
          )}
        </InfoRow>
      </div>
    </Card>
  )
}

export default React.memo(VehicleFile)
