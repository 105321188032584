import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

const FormEffect = ({ onFormGetsDirty }) => {
  const context = useFormikContext()

  useEffect(() => {
    if (context.dirty) {
      onFormGetsDirty && onFormGetsDirty(context.dirty)
    }
  }, [onFormGetsDirty, context])

  return null
}

export default React.memo(FormEffect)
