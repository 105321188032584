import React, { useContext, useReducer, useCallback } from 'react'
import { ADD_TRACK_VEHICLE, REMOVE_TRACK_VEHICLE, SET_MAX_RENDERED_POSITIONS, SET_SEARCH_PARAMS } from './actions'

export const TrackAssistantContext = React.createContext({})

const { Provider } = TrackAssistantContext

export const MAX_RENDERED_POSITTIONS = 100

export const TrackAssistantProvider = ({ children }) => {
  const [{ trackedVehicles, maxRenderedPositions, searchParams }, dispatch] = useReducer(
    (prevState, { type, payload }) => {
      const trackedVehicles = new Set(prevState.trackedVehicles)
      switch (type) {
        case ADD_TRACK_VEHICLE:
          trackedVehicles.add(payload.vehicleId)
          return {
            ...prevState,
            trackedVehicles: Array.from(trackedVehicles),
          }

        case REMOVE_TRACK_VEHICLE:
          trackedVehicles.delete(payload.vehicleId)
          return {
            ...prevState,
            trackedVehicles: Array.from(trackedVehicles),
          }

        case SET_MAX_RENDERED_POSITIONS:
          return {
            ...prevState,
            maxRenderedPositions: payload.maxRenderedPositions,
          }

        case SET_SEARCH_PARAMS:
          return {
            ...prevState,
            searchParams: payload.searchParams || {
              limit: 50,
            },
          }

        default:
          return prevState
      }
    },
    {
      trackedVehicles: [],
      maxRenderedPositions: MAX_RENDERED_POSITTIONS,
      searchParams: {
        limit: 50,
      },
    },
  )

  const addTrackingVehicle = useCallback(({ vehicleId }) => {
    dispatch({ type: ADD_TRACK_VEHICLE, payload: { vehicleId } })
  }, [])

  const removeTrackingVehicle = useCallback(({ vehicleId }) => {
    dispatch({ type: REMOVE_TRACK_VEHICLE, payload: { vehicleId } })
  }, [])

  const isTracked = useCallback(
    (vehicleId) => {
      return new Set(trackedVehicles).has(vehicleId)
    },
    [trackedVehicles],
  )

  const setMaxRenderedPositions = useCallback((maxRenderedPositions) => {
    dispatch({ type: SET_MAX_RENDERED_POSITIONS, payload: { maxRenderedPositions } })
  }, [])

  const setSearchParams = useCallback((searchParams) => {
    dispatch({ type: SET_SEARCH_PARAMS, payload: { searchParams } })
  }, [])

  return (
    <Provider
      value={{
        trackedVehicles,
        isTracked,
        addTrackingVehicle,
        removeTrackingVehicle,
        setMaxRenderedPositions,
        maxRenderedPositions,
        setSearchParams,
        searchParams,
      }}
    >
      {children}
    </Provider>
  )
}

export const useTrackAssistant = () => {
  const ctx = useContext(TrackAssistantContext)
  if (!ctx) {
    throw Error('The `useTrackAssistant` hook must be called from a descendent of the `TrackAssistantProvider`.')
  }

  return ctx
}
