import Map from 'components/molecules/map/Map'
import L from 'leaflet'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import VehicleMarker from '../../../../components/molecules/vehicleMarker/VehicleMarker'
import routes, { generateRoute } from '../../../../config/routes'
import { useTrackAssistant } from '../../../../hooks/trackAssistant/useTrackAssistant'
import { getCompanyId } from '../../../../modules/auth/selectors'
import { useVehicles, useVehiclesLastPosition } from '../../../../modules/vehicles/hooks'

const Tracking = ({ width, height, mapFocused, ...rest }) => {
  const { trackedVehicles } = useTrackAssistant()
  const companyId = useSelector(getCompanyId)
  const positionsQueries = useVehiclesLastPosition(
    { companyId, vehiclesIds: trackedVehicles },
    { refetchInterval: 10000 },
  )
  const { data: vehicles } = useVehicles({ companyId })
  const history = useHistory()

  const positionsPerVehicle = React.useMemo(() => {
    const positionsPerVehicle = {}

    trackedVehicles.forEach((vehicleId, idx) => {
      const position = positionsQueries[idx]?.data
      if (!position) {
        return
      }
      positionsPerVehicle[vehicleId] = position
    })

    return positionsPerVehicle
  }, [positionsQueries, trackedVehicles])

  const calculateMapBounds = () => {
    const bounds = []
    const positions = Object.values(positionsPerVehicle)

    if (positions.length === 0) {
      // Default bounds
      bounds.push([43.083219, -7.3041187])
      bounds.push([37.72268, -0.6519217])
    } else {
      positions.forEach((position) => {
        if (!position) {
          return
        }
        bounds.push([position.latitud / 100000, position.longitud / 100000])
      })
    }
    return new L.LatLngBounds(bounds)
  }

  return (
    <Map width={width} height={height} mapFocused={mapFocused} bounds={calculateMapBounds()} animate>
      {Object.keys(positionsPerVehicle).map((vehicleId) => {
        const position = positionsPerVehicle[vehicleId]
        if (!position) {
          return null
        }
        const vehicle = vehicles.find((vehicle) => {
          return parseInt(vehicle.idvehiculo) === parseInt(vehicleId)
        })
        return (
          <VehicleMarker
            key={vehicleId}
            position={position}
            vehicle={vehicle}
            tooltipEnabled
            eventHandlers={{
              click: () => {
                history.push(generateRoute(routes.vehicle.details, [{ name: 'vehicleId', value: vehicle.idvehiculo }]))
              },
            }}
          />
        )
      })}
    </Map>
  )
}

export default React.memo(Tracking)
