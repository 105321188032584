import c from 'classnames'
import ButtonIcon from 'components/atoms/buttonIcon/ButtonIcon'
import Card from 'components/atoms/card/Card'
import Text from 'components/atoms/text/Text'
import React, { useCallback, useState } from 'react'
import IconChevronDown from '../../../../../components/icons/IconChevronDown'
import styles from './SearchFile.module.css'
import Tag from '../../../../../components/atoms/tag/Tag'
import { usePositionsKms, usePositionsRoutes, usePositionsStops } from '../../../../../modules/vehicles/hooks'
import Checkbox from '../../../../../components/atoms/checkbox/Checkbox'
import { getRouteDestination, getRouteDistance, getRouteHours } from '../../../../../modules/vehicles/utils'

const SearchFile = ({ className, hiddenRoutes = [], onHideRouteChange, positions }) => {
  const [collapsed, setCollapsed] = useState(true)

  const kms = usePositionsKms({ positions })
  const stops = usePositionsStops({ positions })?.length
  const routes = usePositionsRoutes({ positions })

  const groupedRoutes = React.useMemo(() => {
    const groups = {}
    routes?.forEach?.((route, index) => {
      const { day } = getRouteHours(route)
      if (!groups[day]) {
        groups[day] = []
      }
      groups[day].push({ id: index, route })
    })
    return groups
  }, [routes])

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  return (
    <Card className={c(styles.content, collapsed && styles.collapsed, className)}>
      <div className={styles.header}>
        <Text weight="600" variant="white" size="l">
          Búsqueda
        </Text>

        <ButtonIcon
          onClick={toggleCollapsed}
          icon={<IconChevronDown className={styles.collapseIcon} variant="white" />}
        />
      </div>

      <div className={styles.tags}>
        <Tag className={styles.tag}>{`${stops} Paradas`}</Tag>
        <Tag className={styles.tag}>{`${parseFloat(kms ?? 0)?.toFixed?.(2) ?? '0'}Km recorridos`}</Tag>
        <Tag className={styles.tag}>{`${routes?.length} Rutas`}</Tag>
      </div>

      <div className={styles.infoContent}>
        {Object.keys(groupedRoutes)?.map?.((day, index) => {
          const groupRoutes = groupedRoutes[day]
          return (
            <div key={`group-${index}`} className={styles.group}>
              <div className={styles.groupDate}>
                <Text weight="500" size="sm">
                  {day}
                </Text>
              </div>

              {groupRoutes?.map?.(({ id, route }) => {
                const { start, end } = getRouteHours(route)
                const isChecked = !hiddenRoutes?.has?.(id)
                return (
                  <div className={styles.row}>
                    <Checkbox
                      className={styles.checkbox}
                      isChecked={isChecked}
                      name={`isRendered_${id}`}
                      value={true}
                      onChange={() => onHideRouteChange?.({ index: id })}
                    />
                    <div className={styles.rowContent}>
                      <div className={styles.rowHeader}>
                        <Text weight="600" size="m">
                          {`${start} - ${end}`}
                        </Text>

                        <div className={styles.routeTags}>
                          <Tag className={styles.routeTag}>{`${getRouteDistance(route)}km`}</Tag>
                        </div>
                      </div>

                      <Text size="s">Destino: {`${getRouteDestination(route)}`}</Text>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default React.memo(SearchFile)
