import { useReducer, useCallback } from 'react'

const useDirtyForm = () => {
  const [isDirty, dispatchIsDirty] = useReducer((state, newState) => {
    if (state !== newState) {
      return newState
    }
    return state
  }, false)

  const onFormGetsDirty = useCallback((isDirty) => {
    dispatchIsDirty(isDirty)
  }, [])

  const clean = useCallback(() => {
    dispatchIsDirty(false)
  }, [])

  return [isDirty, onFormGetsDirty, clean]
}

export default useDirtyForm
