import React, { Fragment } from 'react'
import Radio from '../../atoms/radio/Radio'
import Text from '../../atoms/text/Text'
import FormField from '../formField/FormField'
import styles from './RadioGroup.module.css'

const RadioGroup = ({ name, value, options = [], touched, error, className, size, ...rest }) => {
  return (
    <Fragment>
      {options.map((option) => {
        return (
          <FormField
            key={`radio-${option.value}`}
            size={size}
            className={className}
            field={
              <Radio
                name={name}
                isChecked={option.value === value}
                label={option.label}
                value={option.value}
                {...rest}
              />
            }
          />
        )
      })}
      {touched && error && (
        <Text size="sm" variant="negative" element="span" className={styles.error}>
          {error}
        </Text>
      )}
    </Fragment>
  )
}

export default React.memo(RadioGroup)
