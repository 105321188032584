import React from 'react'
import styles from './H.module.css'
import c from 'classnames'
import Text from '../../atoms/text/Text'

const H = ({ children, className, ...rest }) => {
  return (
    <Text className={c(styles.header, className)} size="xl" weight="600" element="h1" {...rest}>
      {children}
    </Text>
  )
}

export default React.memo(H)
