import React, { useCallback } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getAuthStatus } from '../../../modules/auth/selectors'
import { useSelector } from 'react-redux'
import SplashScreen from '../../../screens/splash/SplashScreen'
import { AUTH_STATUS } from '../../../modules/auth/reducer'
import routes from '../../../config/routes'

const PrivateRoute = ({ component: Component, componentProps = {}, ...rest }) => {
  const status = useSelector(getAuthStatus)

  const isLogged = useCallback(() => {
    return status === AUTH_STATUS.logged
  }, [status])

  const isCheckingAuth = useCallback(() => {
    return status === AUTH_STATUS.checking
  }, [status])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isCheckingAuth()) {
          return <SplashScreen />
        }

        const userLogged = isLogged()
        const computedProps = { ...props, ...componentProps }
        return userLogged ? (
          <Component {...computedProps} />
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

export default React.memo(PrivateRoute)
